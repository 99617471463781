import i18n from "../translations/i18n";

function toSnakeCase(string, plural = false) {
  if (!string) return "";

  const converted = string.replace(/([a-z\d])([A-Z])/g, "$1_$2").toLowerCase();
  if (plural) return pluralize(converted);
  else return converted;
}

function toPascalCase(string) {
  return (
    string.charAt(0).toUpperCase() +
    string.slice(1).replace(/_([a-z])/g, (_, p) => p.toUpperCase())
  );
}

function pluralize(noun) {
  if (noun.match(/sis$/)) {
    return noun.replace(/sis$/, "ses");
  } else if (noun.match(/(x|ch|ss|sh)$/)) {
    return noun.replace(/(x|ch|ss|sh)$/, "$1es");
  } else if (noun.match(/([^aeiouy]|qu)y$/)) {
    return noun.replace(/([^aeiouy]|qu)y$/, "$1ies");
  } else {
    return noun.replace(/$/, "s");
  }
}

function checkTranslation(key) {
  if (key && key.includes("$t$")) {
    return i18n.te(`custom.${key.split("$t$")[1]}`);
  } else {
    return key && typeof key === "string" && key.length > 0;
  }
}

function getTranslation(key) {
  if (!key) {
    return "";
  } else if (key.split("$t$").length > 1) {
    return i18n.t(`custom.${key.split("$t$")[1]}`);
  } else {
    return key;
  }
}

function toSelect(inputHash, customTrans = false) {
  return Object.keys(inputHash).map((key) => {
    return {
      value: key,
      text: customTrans
        ? getTranslation(inputHash[key])
        : i18n.t(inputHash[key]),
    };
  });
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function removeAccents(string) {
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function customFilter(_item, queryText, itemText) {
  return removeAccents(itemText.toLowerCase()).includes(
    removeAccents(queryText.toLowerCase())
  );
}

function isConfigurationModel(model) {
  return [
    "asset_category",
    "person_category",
    "rule",
    "price_item",
    "connector",
    "mapper",
    "external_email",
    "issue_state",
    "issue_priority",
    "tag_definition",
    "person_relation",
    "field",
    "report",
    "translation",
    "holiday",
  ].includes(model);
}

function convertFileSize(size) {
  if (!size) return "";

  if (size >= 1048576) {
    return `${Math.round(size / 1048576)} MB`;
  } else {
    return `${Math.ceil(size / 1024)} kB`;
  }
}

export {
  toSnakeCase,
  toPascalCase,
  pluralize,
  getTranslation,
  checkTranslation,
  toSelect,
  capitalize,
  removeAccents,
  customFilter,
  isConfigurationModel,
  convertFileSize,
};
