import { defineStore } from "pinia";
import i18n from "../translations/i18n";

export const useMessageStore = defineStore("message", {
  state: () => ({
    text: "Default message",
    type: "info",
    timeout: 5000,
    display: false,
  }),
  getters: {},
  actions: {
    changeMessage(text, type, timeout) {
      this.text = text;
      this.type = type;
      this.timeout = timeout;
      this.display = true;
    },
    errorMessage(action, modelSing, response, timeout) {
      const verbTr = i18n.t("messages.cant");
      const actionTr = i18n.t(`action.${action}`).toLowerCase();
      const modelTr = i18n.t(`${modelSing}.acc`);
      const permTr = response?.data?.transaction?.permission
        ? i18n.t("messages.noPermissionError")
        : "";
      const validation = response?.data?.transaction?.message ?? "";
      const text = `${verbTr} ${actionTr} ${modelTr}. ${permTr} ${validation}`;

      this.changeMessage(text, "error", timeout);
    },
    successMessage(action, model, timeout) {
      const modelTr = i18n.t(`${model}.${model}`);
      const verbTr = i18n.t(`${model}.alert.verb`);
      const actionTr = i18n.t(`${model}.alert.${action}`);
      const text = `${modelTr} ${verbTr} ${actionTr}.`;

      this.changeMessage(text, "success", timeout);
    },
    customMessage(key, type, params, timeout) {
      this.changeMessage(i18n.t(key, params), type, timeout);
    },
  },
});
