import _DatabaseModel from "./_DatabaseModel";

class Holiday extends _DatabaseModel {
  static model = "holiday";
  static editDialogComponent = "holidays-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name", class: "text-no-wrap" },
    {
      textKey: "holiday.label.country",
      value: "country",
      class: "text-no-wrap",
    },
    { textKey: "holiday.label.date", value: "date", class: "text-no-wrap" },
    {
      textKey: "other.actions",
      value: "actions",
      sortable: false,
      align: "right",
      cellClass: "text-no-wrap",
    },
  ];

  static operations = ["export"];

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: object.country,
      date: object.date,
      actions: ["edit", "delete"],
    };
  }
}

export default Holiday;
