import {
  mdiExclamationThick,
  mdiFlag,
  mdiViewDashboardVariant,
  mdiClipboardOutline,
  mdiCertificate,
  mdiScaleBalance,
  mdiAccountGroup,
  mdiOfficeBuilding,
  mdiFileDocument,
  mdiWrench,
  mdiWarehouse,
  mdiPowerPlug,
  mdiCog,
  mdiCogs,
  mdiAccount,
  mdiAccountCog,
  mdiClockTimeFiveOutline,
  mdiBell,
  mdiRss,
  mdiBriefcaseVariant,
  mdiCalendarMonth,
  mdiEmailOutline,
  mdiSpeedometer,
  mdiEyeCheck,
  mdiFileImport,
  mdiAlertDecagram,
  mdiAlertOutline,
  mdiShieldAlertOutline,
  mdiShieldLockOpenOutline,
  mdiAutoFix,
  mdiAccountMultiple,
  mdiCurrencyEur,
  mdiPackageVariantClosed,
  mdiShuffleVariant,
  mdiApplicationCog,
  mdiFilter,
  mdiTag,
  mdiFile,
  mdiTextBox,
  mdiOfficeBuildingCog,
  mdiHistory,
  mdiTranslate,
  mdiBullhorn,
} from "@mdi/js";

const FIREBASE_KEY =
  "BN4y-6Wf_rUy0IMXJSSxszmW0uQu0dUAtE1dkQZcdduyfV5Yr37AKPW19muRM73cdW2DvH_cs7L4GTfB4W_Dgc8";

const WIKI_URL = "wiki.ausem.io";

const APP_MENU = Object.freeze([
  {
    perms_name: "profile",
    multiple: true,
    noSetter: true,
    icon: mdiAccount,
    perms_name_array: ["account", "preferences", "devices", "about", "license"],
    items: [
      {
        title_t: "profile.account",
        url: "account",
        wiki_link: "profile/account/account/",
        perms_name: "account",
      },
      {
        title_t: "profile.preferences",
        url: "preferences",
        wiki_link: "profile/preferences/preferences/",
        perms_name: "preferences",
      },
      {
        title_t: "profile.devices",
        url: "devices",
        wiki_link: "profile/devices/devices/",
        perms_name: "devices",
      },
      {
        title_t: "profile.about",
        url: "about",
        wiki_link: "profile/about/about/",
        perms_name: "about",
      },
      {
        title_t: "license.license",
        url: "license",
        wiki_link: "profile/license/license/",
        perms_name: "license",
      },
    ],
  },
  {
    perms_name: "main_dashboard_screens",
    title_t: "dashboard_screen.dashboard_screens",
    multiple: true,
    noSetter: true,
    icon: mdiViewDashboardVariant,
    perms_name_array: ["dashboard_screens", "kpis"],
    items: [
      {
        title_t: "dashboard_screen.dashboard_screens",
        perms_name: "dashboard_screens",
        icon: mdiViewDashboardVariant,
        url: "dashboard_screens",
        wiki_link: "dashboards/dashboards/dashboards/",
        tooltip: "Shift + B",
      },
      {
        title_t: "kpi.kpi",
        perms_name: "kpis",
        icon: mdiSpeedometer,
        url: "kpis",
        wiki_link: "dashboards/kpi/kpi/",
        items: [
          { perms_name: "kpis_tab_preview" },
          { perms_name: "kpis_tab_definition" },
          { perms_name: "kpis_tab_data" },
        ],
      },
    ],
  },
  {
    perms_name: "main_issues",
    title_t: "issue.issues",
    multiple: true,
    noSetter: true,
    icon: mdiClipboardOutline,
    perms_name_array: ["issues", "planning_calendar"],
    items: [
      {
        title_t: "issue.issues",
        perms_name: "issues",
        icon: mdiClipboardOutline,
        url: "issues",
        wiki_link: "issues/issues/issues/",
        tooltip: "Shift + I",
        items: [
          { perms_name: "issues_tab_timeline" },
          { perms_name: "issues_tab_transactions" },
          { perms_name: "issues_tab_documents" },
          { perms_name: "issues_tab_notes" },
          { perms_name: "issues_tab_persons" },
          { perms_name: "issues_tab_assets" },
          { perms_name: "issues_tab_work_reports" },
          { perms_name: "issues_tab_locations" },
          { perms_name: "import_issues", icon: mdiFileImport },
        ],
      },
      {
        title_t: "calendar.planning_calendar",
        perms_name: "planning_calendar",
        url: "planning_calendar",
        wiki_link: "issues/planning_calendar/planningcalendar/",
        tooltip: "Shift + C",
        icon: mdiCalendarMonth,
      },
    ],
  },
  {
    perms_name: "main_persons",
    title_t: "person.persons",
    multiple: true,
    noSetter: true,
    icon: mdiAccountGroup,
    perms_name_array: [
      "persons",
      "person_categories",
      "permissions",
      "roles",
      "users",
    ],
    items: [
      {
        title_t: "person.persons",
        perms_name: "persons",
        icon: mdiAccountGroup,
        url: "persons",
        wiki_link: "persons/persons/persons/",
        tooltip: "Shift + P",
        items: [
          { perms_name: "persons_tab_documents" },
          { perms_name: "persons_tab_external_guids" },
          { perms_name: "persons_tab_assets" },
          { perms_name: "persons_tab_work_reports" },
          { perms_name: "persons_tab_issues" },
          { perms_name: "persons_tab_calendar" },
          { perms_name: "import_persons", icon: mdiFileImport },
        ],
      },
      {
        title_t: "category.categories",
        perms_name: "person_categories",
        icon: mdiAccountCog,
        url: "person_categories",
        wiki_link: "persons/categories/categories/",
        items: [{ perms_name: "categories_tab_templates" }],
      },
      {
        title_t: "permission.permissions",
        perms_name: "permissions",
        icon: mdiEyeCheck,
        url: "permissions",
        wiki_link: "persons/permissions/permissions/",
      },
      {
        title_t: "role.roles",
        perms_name: "roles",
        url: "roles",
        wiki_link: "persons/roles/roles/",
        icon: mdiAccountMultiple,
        items: [
          { perms_name: "roles_tab_role" },
          { perms_name: "roles_tab_permissions" },
          { perms_name: "roles_tab_persons" },
          { perms_name: "roles_tab_issue_state_transitions" },
        ],
      },
      {
        title_t: "user.users",
        perms_name: "users",
        icon: mdiAccount,
        url: "users",
        wiki_link: "persons/users/users/",
        items: [{ perms_name: "import_users", icon: mdiFileImport }],
      },
    ],
  },
  {
    perms_name: "main_assets",
    title_t: "asset.assets",
    multiple: true,
    noSetter: true,
    icon: mdiOfficeBuilding,
    perms_name_array: ["assets", "asset_categories"],
    items: [
      {
        title_t: "asset.assets",
        perms_name: "assets",
        url: "assets",
        wiki_link: "assets/assets/assets/",
        tooltip: "Shift + A",
        icon: mdiOfficeBuilding,
        items: [
          { perms_name: "assets_tab_timeline" },
          { perms_name: "assets_tab_documents" },
          { perms_name: "assets_tab_locations" },
          { perms_name: "assets_tab_external_guids" },
          { perms_name: "assets_tab_persons" },
          { perms_name: "assets_tab_issues" },
          { perms_name: "assets_tab_calendar" },
          { perms_name: "assets_tab_services" },
          { perms_name: "assets_tab_work_reports" },
          { perms_name: "assets_tab_network_graph" },
          { perms_name: "import_assets", icon: mdiFileImport },
        ],
      },
      {
        title_t: "category.categories",
        perms_name: "asset_categories",
        icon: mdiOfficeBuildingCog,
        url: "asset_categories",
        wiki_link: "assets/categories/categories/",
        items: [
          { perms_name: "categories_tab_templates" },
          { perms_name: "categories_tab_threats" },
        ],
      },
    ],
  },
  {
    perms_name: "main_documents",
    title_t: "document.documents",
    multiple: true,
    noSetter: true,
    icon: mdiFileDocument,
    perms_name_array: ["documents"],
    items: [
      {
        title_t: "document.documents",
        perms_name: "documents",
        icon: mdiFileDocument,
        url: "documents",
        wiki_link: "documents/documents/documents/",
        tooltip: "Shift + D",
        items: [
          { perms_name: "documents_tab_entity" },
          { perms_name: "import_documents", icon: mdiFileImport },
        ],
      },
    ],
  },
  {
    perms_name: "main_services",
    title_t: "service.services",
    multiple: true,
    noSetter: true,
    icon: mdiWrench,
    perms_name_array: [
      "services",
      "contracts",
      "maintenance_plan",
      "standby_plan",
      "rules",
    ],
    items: [
      {
        title_t: "service.services",
        perms_name: "services",
        icon: mdiWrench,
        url: "services",
        wiki_link: "services/services/services/",
        tooltip: "Shift + S",
        items: [
          { perms_name: "services_tab_assets" },
          { perms_name: "services_workflow_tab" },
          { perms_name: "services_tab_templates" },
          { perms_name: "services_tab_persons" },
          { perms_name: "services_tab_documents" },
          { perms_name: "services_tab_issues" },
          { perms_name: "services_tab_sla" },
          { perms_name: "import_services", icon: mdiFileImport },
        ],
      },
      {
        title_t: "contract.contracts",
        perms_name: "contracts",
        icon: mdiBriefcaseVariant,
        url: "contracts",
        wiki_link: "services/contracts/contracts/",
        items: [
          { perms_name: "contracts_tab_documents" },
          { perms_name: "import_contracts", icon: mdiFileImport },
        ],
      },
      {
        title_t: "calendar.maintenance_plan",
        perms_name: "maintenance_plan",
        url: "maintenance_plan",
        wiki_link: "services/maintenance_plan/maintenance_plan/",
        icon: mdiCalendarMonth,
      },
      {
        title_t: "service_person.label.standby_plan",
        perms_name: "standby_plan",
        url: "standby_plan",
        wiki_link: "services/standby_plan/standby_plan/",
        icon: mdiCalendarMonth,
      },
      {
        title_t: "rule.rules",
        perms_name: "rules",
        icon: mdiCalendarMonth,
        url: "rules",
        wiki_link: "services/rules/rules/",
        items: [
          { perms_name: "rules_tab_documents" },
          { perms_name: "rules_tab_assets" },
          { perms_name: "import_rules", icon: mdiFileImport },
        ],
      },
    ],
  },
  {
    perms_name: "main_risk_management",
    title_t: "risk_management.risk_management",
    multiple: true,
    noSetter: true,
    icon: mdiAlertDecagram,
    perms_name_array: [
      "risks",
      "primary_assets",
      "asset_groups",
      "controls_management",
      "linkages_setting",
      // "soas",
      "threats",
      "vulnerabilities",
      "controls",
      "consequences",
      "likelihoods",
      "risk_levels",
    ],
    items: [
      {
        title_t: "risk.label.risk_analysis",
        perms_name: "risks",
        wiki_link: "risk_management/risks/risks/",
        icon: mdiCog,
        url: "risks",
      },
      {
        title_t: "primary_asset.primary_assets",
        perms_name: "primary_assets",
        wiki_link: "risk_management/primary_assets/primary_assets/",
        icon: mdiCog,
        url: "primary_assets",
        items: [{ perms_name: "primary_asset_tab_asset_groups" }],
      },
      {
        title_t: "asset_group.asset_groups",
        perms_name: "asset_groups",
        wiki_link: "risk_management/asset_groups/asset_groups/",
        icon: mdiCog,
        url: "asset_groups",
        items: [
          { perms_name: "asset_group_tab_assets" },
          { perms_name: "asset_group_tab_controls" },
        ],
      },
      {
        title_t: "controls_management.controls_management",
        perms_name: "controls_management",
        wiki_link: "risk_management/controls_management/controls_management/",
        icon: mdiCog,
        url: "controls_management",
      },
      {
        title_t: "app_parts.linkages_setting",
        perms_name: "linkages_setting",
        wiki_link: "risk_management/linkages_setting/linkages_setting/",
        icon: mdiCog,
        url: "linkages_setting",
      },
      // {
      //   title_t: "soa.soas",
      //   perms_name: "soas",
      //   wiki_link: "risk_management/soas/soas/",
      //   icon: mdiCog,
      //   url: "soas",
      //   items: [{ perms_name: "soa_tab_controls" }],
      // },
      {
        title_t: "threat.threats",
        perms_name: "threats",
        icon: mdiShieldAlertOutline,
        url: "threats",
        wiki_link: "risk_management/threats/threats/",
        items: [
          { perms_name: "import_threats", icon: mdiFileImport },
          { perms_name: "threats_tab_vulnerabilities" },
        ],
      },
      {
        title_t: "vulnerability.vulnerabilities",
        perms_name: "vulnerabilities",
        icon: mdiShieldLockOpenOutline,
        url: "vulnerabilities",
        wiki_link: "risk_management/vulnerabilities/vulnerabilities/",
        items: [
          { perms_name: "import_vulnerabilities", icon: mdiFileImport },
          { perms_name: "vulnerability_tab_controls" },
        ],
      },
      {
        title_t: "control.controls",
        perms_name: "controls",
        icon: mdiAlertOutline,
        url: "controls",
        wiki_link: "risk_management/controls/controls/",
        items: [{ perms_name: "import_controls", icon: mdiFileImport }],
      },
      {
        title_t: "consequence.consequences",
        perms_name: "consequences",
        icon: mdiShieldAlertOutline,
        url: "consequences",
        wiki_link: "risk_management/consequences/consequences/",
      },
      {
        title_t: "likelihood.likelihoods",
        perms_name: "likelihoods",
        icon: mdiShieldAlertOutline,
        url: "likelihoods",
        wiki_link: "risk_management/likelihoods/likelihoods/",
      },
      {
        title_t: "risk_level.risk_levels",
        perms_name: "risk_levels",
        icon: mdiShieldAlertOutline,
        url: "risk_levels",
        wiki_link: "risk_management/risk_levels/risk_levels/",
      },
    ],
  },
  {
    perms_name: "main_audits",
    title_t: "audit.audits",
    multiple: true,
    noSetter: true,
    icon: mdiCertificate,
    perms_name_array: ["audits", "standard_soas", "standards"],
    items: [
      {
        title_t: "audit.audits",
        perms_name: "audits",
        icon: mdiCertificate,
        url: "audits",
        wiki_link: "audits/audits/audits/",
        items: [
          { perms_name: "audit_tab_valuation" },
          { perms_name: "audit_tab_soa" },
          { perms_name: "audits_tab_documents" },
        ],
      },
      {
        title_t: "standard_soa.standard_soas",
        perms_name: "standard_soas",
        icon: mdiCertificate,
        url: "standard_soas",
        wiki_link: "audits/standard_soas/standard_soas/",
        items: [{ perms_name: "standard_soa_tab_valuation" }],
      },
      {
        title_t: "standard.laws_standards",
        perms_name: "standards",
        icon: mdiScaleBalance,
        url: "standards",
        wiki_link: "audits/standards/standards/",
        items: [
          { perms_name: "audit_tab_valuation" },
          { perms_name: "import_standards", icon: mdiFileImport },
        ],
      },
    ],
  },
  {
    perms_name: "main_warehouses",
    title_t: "warehouse.warehouses",
    multiple: true,
    noSetter: true,
    icon: mdiWarehouse,
    perms_name_array: ["warehouses", "price_lists", "price_items"],
    items: [
      {
        title_t: "warehouse.warehouses",
        perms_name: "warehouses",
        icon: mdiWarehouse,
        url: "warehouses",
        wiki_link: "warehouses/warehouses/warehouses/",
        tooltip: "Shift + W",
        items: [
          { perms_name: "warehouses_tab_balance" },
          { perms_name: "warehouses_tab_transactions" },
          { perms_name: "warehouses_tab_external_guids" },
          { perms_name: "import_warehouses", icon: mdiFileImport },
        ],
      },
      {
        title_t: "price_list.price_lists",
        perms_name: "price_lists",
        icon: mdiCurrencyEur,
        url: "price_lists",
        wiki_link: "warehouses/price_lists/price_lists/",
        items: [
          { perms_name: "price_lists_tab_items" },
          { perms_name: "import_price_lists", icon: mdiFileImport },
        ],
      },
      {
        title_t: "price_item.price_items",
        perms_name: "price_items",
        icon: mdiPackageVariantClosed,
        url: "price_items",
        wiki_link: "warehouses/price_items/price_items/",
        items: [
          { perms_name: "price_items_tab_external_guids" },
          { perms_name: "price_items_tab_warehouses" },
          { perms_name: "import_price_items", icon: mdiFileImport },
        ],
      },
    ],
  },
  {
    perms_name: "main_connectors",
    title_t: "connector.connectors",
    multiple: true,
    noSetter: true,
    icon: mdiPowerPlug,
    perms_name_array: ["external_emails", "connectors", "mappers"],
    items: [
      {
        title_t: "connector.connectors",
        perms_name: "connectors",
        icon: mdiPowerPlug,
        url: "connectors",
        wiki_link: "connectors/connectors/connectors/",
        items: [{ perms_name: "connectors_tab_run" }],
      },
      {
        title_t: "mapper.mappers",
        perms_name: "mappers",
        icon: mdiShuffleVariant,
        url: "mappers",
        wiki_link: "connectors/mappers/mappers/",
        items: [
          { perms_name: "mappers_tab_definition" },
          { perms_name: "mappers_tab_run" },
        ],
      },
      {
        title_t: "external_email.external_emails",
        perms_name: "external_emails",
        icon: mdiEmailOutline,
        url: "external_emails",
        wiki_link: "connectors/external_emails/external_emails/",
        items: [{ perms_name: "external_emails_tab_issues" }],
      },
    ],
  },
  {
    perms_name: "settings",
    title_t: "profile.settings",
    multiple: true,
    noSetter: true,
    icon: mdiAccountCog,
    perms_name_array: [
      "data_sets",
      "snippets",
      "notifications",
      "subscriptions",
      "reminders",
    ],
    items: [
      {
        title_t: "data_set.data_sets",
        perms_name: "data_sets",
        icon: mdiFilter,
        url: "data_sets",
        wiki_link: "settings/filters/filters/",
        items: [{ perms_name: "data_sets_tab_definition" }],
      },
      {
        title_t: "snippet.snippets",
        perms_name: "snippets",
        icon: mdiTextBox,
        url: "snippets",
        wiki_link: "settings/snippets/snippets/",
      },
      {
        title_t: "notification.notifications",
        icon: mdiBell,
        url: "notifications",
        wiki_link: "settings/notifications/notifications/",
        perms_name: "notifications",
      },
      {
        title_t: "subscription.subscriptions",
        icon: mdiRss,
        url: "subscriptions",
        wiki_link: "settings/subscriptions/subscriptions/",
        perms_name: "subscriptions",
        items: [{ perms_name: "subscriptions_tab_subscribed_items" }],
      },
      {
        title_t: "reminder.reminders",
        icon: mdiClockTimeFiveOutline,
        url: "reminders",
        wiki_link: "settings/reminders/reminders/",
        perms_name: "reminders",
      },
    ],
  },
  {
    perms_name: "administration",
    title_t: "administration.administration",
    multiple: true,
    noSetter: true,
    icon: mdiCog,
    perms_name_array: [
      "tag_definitions",
      "activities",
      "reports",
      "fields",
      "automated_actions",
      "translations",
      "issue_states",
      "issue_priorities",
      "person_relations",
      "notice",
    ],
    items: [
      {
        title_t: "issue_state.issue_states",
        url: "issue_states",
        wiki_link: "administration/issue_states/issue_states/",
        perms_name: "issue_states",
        icon: mdiFlag,
        items: [{ perms_name: "import_issue_states", icon: mdiFileImport }],
      },
      {
        title_t: "issue_priority.issue_priorities",
        url: "issue_priorities",
        wiki_link: "administration/issue_priorities/issue_priorities/",
        perms_name: "issue_priorities",
        icon: mdiExclamationThick,
        items: [{ perms_name: "import_issue_priorities", icon: mdiFileImport }],
      },
      {
        title_t: "tag_definition.tag_definitions",
        perms_name: "tag_definitions",
        icon: mdiTag,
        url: "tag_definitions",
        wiki_link: "administration/tags/tags/",
        items: [{ perms_name: "import_tag_definitions", icon: mdiFileImport }],
      },
      {
        title_t: "person_relation.person_relations",
        url: "person_relations",
        wiki_link: "administration/person_relations/person_relations/",
        perms_name: "person_relations",
        icon: mdiAccountGroup,
        items: [{ perms_name: "import_person_relations", icon: mdiFileImport }],
      },
      {
        title_t: "field.fields",
        perms_name: "fields",
        icon: mdiApplicationCog,
        url: "fields",
        wiki_link: "administration/fields/fields/",
        items: [
          { perms_name: "fields_tab_usage" },
          { perms_name: "import_fields", icon: mdiFileImport },
        ],
      },
      {
        title_t: "report.reports",
        perms_name: "reports",
        icon: mdiFile,
        url: "reports",
        wiki_link: "administration/reports/reports/",
      },
      {
        title_t: "automated_action.automated_actions",
        perms_name: "automated_actions",
        url: "automated_actions",
        wiki_link: "administration/automated_actions/automated_actions/",
        icon: mdiAutoFix,
        items: [
          { perms_name: "automated_actions_tab_definition" },
          { perms_name: "automated_actions_tab_run" },
        ],
      },
      {
        title_t: "translation.translations",
        perms_name: "translations",
        icon: mdiTranslate,
        url: "translations",
        wiki_link: "administration/translations/translations/",
        items: [{ perms_name: "import_translations", icon: mdiFileImport }],
      },
      {
        title_t: "implementation.menu.notice",
        url: "notice",
        wiki_link: "administration/notice/notice/",
        icon: mdiBullhorn,
        perms_name: "notice",
      },
      {
        title_t: "activity.activities",
        perms_name: "activities",
        icon: mdiHistory,
        url: "activities",
        wiki_link: "administration/activities/activities/",
      },
    ],
  },
  {
    perms_name: "implementation",
    title_t: "implementation.implementation",
    multiple: true,
    noSetter: true,
    icon: mdiCogs,
    perms_name_array: [
      "installation",
      "statistics",
      "health_check",
      "mail_server",
      "public_form",
      "document_settings",
      "user_settings",
      "holidays",
      "background_jobs",
    ],
    items: [
      {
        title_t: "implementation.menu.installation",
        url: "installation",
        wiki_link: "implementation/installation/installation/",
        perms_name: "installation",
      },
      {
        title_t: "implementation.menu.statistics",
        url: "statistics",
        wiki_link: "implementation/statistics/statistics/",
        perms_name: "statistics",
      },
      {
        title_t: "implementation.menu.health_check",
        url: "health_check",
        wiki_link: "implementation/health_check/health_check/",
        perms_name: "health_check",
      },
      {
        title_t: "implementation.menu.mail_server",
        url: "mail_server",
        wiki_link: "implementation/mail_server/mail_server/",
        perms_name: "mail_server",
      },
      {
        title_t: "implementation.menu.public_form",
        url: "public_form",
        wiki_link: "implementation/public_form/public_form/",
        perms_name: "public_form",
      },
      {
        title_t: "implementation.menu.document_settings",
        url: "document_settings",
        wiki_link: "implementation/document_settings/document_settings/",
        perms_name: "document_settings",
      },
      {
        title_t: "implementation.menu.user_settings",
        url: "user_settings",
        wiki_link: "implementation/user_settings/user_settings/",
        perms_name: "user_settings",
      },
      {
        title_t: "implementation.menu.holidays",
        url: "holidays",
        wiki_link: "implementation/holidays/holidays/",
        perms_name: "holidays",
        items: [{ perms_name: "import_holidays", icon: mdiFileImport }],
      },
      {
        title_t: "implementation.menu.background_jobs",
        url: "background_jobs",
        wiki_link: "implementation/background_jobs/background_jobs/",
        perms_name: "background_jobs",
      },
    ],
  },
]);

const LICENSED_PARTS = Object.freeze({
  assets: "assets",
  documents: "documents",
  contracts: "contracts",
  services: "services",
  standby_plan: "services",
  rules: "rules",
  issues: "issues",
  kpis: "kpis",
  warehouses: "warehouses",
  price_lists: "price_lists",
  price_items: "price_items",
  maintenance_plan: "maintenance_plan",
  planning_calendar: "planning_calendar",
  automated_actions: "automated_actions",
  threats: "risk_management",
  vulnerabilities: "risk_management",
  risks: "risk_management",
  primary_assets: "risk_management",
  controls_management: "risk_management",
  linkages_setting: "risk_management",
  asset_groups: "risk_management",
  // soas: "risk_management",
  consequences: "risk_management",
  likelihoods: "risk_management",
  risk_levels: "risk_management",
  controls: "risk_management",
  import_vulnerabilities: "risk_management",
  import_threats: "risk_management",
  import_risks: "risk_management",
  import_controls: "risk_management",
  assets_tab_network_graph: "risk_management",
  categories_tab_threats: "risk_management",
  assets_tab_risks: "risk_management",
  audits: "risk_management",
  standards: "risk_management",
  standard_soas: "risk_management",
});

const UNCHECKED_PARTS = Object.freeze([
  "account",
  "preferences",
  "devices",
  "about",
  "license",
  "public/issues",
]);

const MODELS = Object.freeze([
  "AppliedControl",
  "Asset",
  "AssetCategory",
  "AssetGroup",
  "AssetLink",
  "AssetsIssue",
  "Audit",
  "AutomatedAction",
  "Category",
  "CategoryHierarchy",
  "CategoryThreat",
  "Cia",
  "Connector",
  "Consequence",
  "Contract",
  "Control",
  "ControlsManagement",
  "DashboardScreen",
  "Dashlet",
  "DataSet",
  "Document",
  "EntityDocument",
  "EntitySubscription",
  "ExternalEmail",
  "ExternalGuid",
  "Field",
  "Holiday",
  "IndoorLocation",
  "Issue",
  "IssueAssignee",
  "IssueLink",
  "IssueNote",
  "IssuePriority",
  "IssueState",
  "IssueStateTransition",
  "Kpi",
  "Likelihood",
  "Location",
  "Mapper",
  "Permission",
  "PermissionsApplication",
  "Person",
  "PersonAsset",
  "PersonCategory",
  "PersonRelation",
  "PersonRole",
  "PriceItem",
  "PriceItemsPriceList",
  "PriceItemsWarehouse",
  "PriceList",
  "PrimaryAsset",
  "Provision",
  "ProvisionQuestion",
  "Question",
  "Recommendation",
  "Reminder",
  "Report",
  "Requirement",
  "Risk",
  "RiskControl",
  "RiskLevel",
  "Role",
  "RoleIssueStateTransition",
  "Rule",
  "Section",
  "Service",
  "ServiceIssueStateSla",
  "ServicePerson",
  "Sla",
  "Snippet",
  "Soa",
  "SoaControl",
  "Standard",
  "StandardSoa",
  "StandardSoaProvision",
  "StandbyTime",
  "Subscription",
  "TagAssignment",
  "TagDefinition",
  "TemplateAssignment",
  "Threat",
  "ThreatVulnerability",
  "Translation",
  "User",
  "Vulnerability",
  "VulnerabilityControl",
  "Warehouse",
  "WarehouseBatch",
  "WarehouseTransaction",
  "WarehouseTransactionItem",
  "WorkReport",
]);

export {
  FIREBASE_KEY,
  WIKI_URL,
  APP_MENU,
  LICENSED_PARTS,
  UNCHECKED_PARTS,
  MODELS,
};
