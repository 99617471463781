import { defineStore } from "pinia";

export const useAppStateStore = defineStore("appState", {
  state: () => ({
    auth: false,
    nextRoute: { path: "/", query: {} },
    selectedObjects: [],
    currentObject: null,
    mode: {
      person: "tree",
      asset: "tree",
      document: "tree",
      person_category: "tree",
      asset_category: "tree",
      issue: "card",
      person_calendar: "card",
    },
    personCalendar: {
      type: "month",
      start: new Date().toISOString().slice(0, 10),
      coloring: "default",
      person_ids: [],
      persons: [],
      asset_ids: [],
      assets: [],
    },
    dashboard: null,
    sortBy: {
      activity: ["created_at"],
      issue: ["created_at"],
      person_calendar: ["created_at"],
      jwt_session: ["updated_at"],
    },
    sortDesc: {
      activity: ["true"],
      issue: ["true"],
      person_calendar: ["true"],
      jwt_session: ["true"],
    },
  }),
  getters: {},
  actions: {
    setMode(model, mode) {
      this.mode[model] = mode;
    },
    setSort(model, by, desc) {
      this.sortBy[model] = by;
      this.sortDesc[model] = desc;
    },
  },
  persist: true,
});
