import _DatabaseModel, { i18n } from "./_DatabaseModel";
import { toSnakeCase } from "../mixins/HelperFunctions";

class Issue extends _DatabaseModel {
  static model = "issue";
  static showSize = 7;
  static cardComponent = "issue-card";
  static editDialogComponent = "issue-change-state";

  static bottomNav = {
    mobile: ["card", "map", "cal"],
    desktop: ["card", "map", "cal"],
  };

  static tableHeaders = [
    { textKey: "form.label.id", value: "id", sortable: false },
    { textKey: "form.label.created_at", value: "created_at" },
    { textKey: "form.label.updated_at", value: "updated_at" },
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
    { textKey: "issue_priority.label.priority", value: "issue_priority_id" },
    { textKey: "issue_state.label.state", value: "issue_state_id" },
    { textKey: "issue.label.resolve_at", value: "resolve_at" },
    {
      textKey: "activity.list_label.created_by",
      value: "creator",
      sortable: false,
    },
    {
      textKey: "issue_assignee.issue_assignees",
      value: "issue_assignees",
      sortable: false,
    },
    {
      textKey: "asset.asset",
      value: "assets",
      sortable: false,
    },
  ];

  static filter = {
    parts: {
      fields: [
        "name",
        "id",
        "issue_state_id",
        "issue_priority_id",
        "tag_definitions",
        "service_id",
        "asset_id",
        "person_id",
        "not_planned",
        "public_token_valid",
      ],
      extended_fields: [
        "detail",
        "description",
        "solution",
        "asset_resource_id",
        "parent_id",
        "detail_on_asset",
        "person_relation_id",
        "creator_id",
        "external_email_id",
        "customer_id",
        "service_organization_id",
        "rule_id",
        "price_list_id",
        "time_filter",
      ],
      time_filter: [
        "created_at",
        "updated_at",
        "planned_at",
        "resolve_at",
        "closed_at",
        "sla_response_time_estimated",
        "sla_response_time_actual",
        "sla_resolution_time_estimated",
        "sla_resolution_time_actual",
      ],
    },
  };

  static operations = [
    "export",
    "report",
    "link_to_document",
    "change_issue_state",
    "add_tag_to_entity",
    "remove_tag_from_entity",
    "add_note_to_issue",
    "add_person_to_issues",
    "remove_person_from_issues",
  ];

  static public_token_valid_options = {
    true: "filter.public_token_valid_levels.public",
    false: "filter.public_token_valid_levels.internal",
  };

  static not_planned_options = {
    true: "filter.not_planned_levels.not_planned",
    false: "filter.not_planned_levels.planned",
  };

  static show = {
    buttons: ["edit"],
    submenuActions: ["link", "reminder", "change_service", "activity"],
    reloadingModels: [
      "location",
      "issue_link",
      "issue_assignee",
      "assets_issue",
      "work_report",
    ],
  };

  static basicInfo(object) {
    return [
      {
        labelKey: "form.label.solution",
        value: object.solution,
        type: "plain",
        condition: object.solution,
      },
      {
        labelKey: "service.service",
        value: object.service,
        type: "link",
        to: "services",
      },
      {
        labelKey: "asset.assets",
        value: object.assets,
        type: "link_array",
        to: "assets",
      },
      {
        labelKey: "issue_assignee.issue_assignees",
        value: object.persons,
        type: "link_array",
        to: "persons",
      },
      {
        labelKey: "form.label.resolve_at",
        value: object.resolve_at,
        type: "date",
      },
      {
        labelKey: "issue.label.public_token_valid",
        value: object.public_token_valid,
        type: "public_link",
      },
      {
        labelKey: "form.label.author",
        value: object.creator,
        type: "link",
        to: toSnakeCase(object.creator_type, true),
      },
      {
        labelKey: "form.label.updated_at",
        value: object.updated_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
      {
        labelKey: "form.label.closed_at",
        value: object.closed_at,
        type: "date_time",
        condition: object.closed_at,
      },
      {
        labelKey: "issue_link.label.related",
        value: object.linked_issues?.related,
        type: "issue_links_array",
        condition: object.linked_issues?.related.length > 0,
      },
      {
        labelKey: "issue_link.label.blocks",
        value: object.linked_issues?.blocks,
        type: "issue_links_array",
        condition: object.linked_issues?.blocks.length > 0,
      },
      {
        labelKey: "issue_link.label.is_blocked_by",
        value: object.linked_issues?.is_blocked_by,
        type: "issue_links_array",
        condition: object.linked_issues?.is_blocked_by.length > 0,
      },
      {
        labelKey: "sla.label.limit_by_sla",
        value: [
          object.sla_response_time_estimated,
          object.sla_resolution_time_estimated,
        ],
        type: "date_time_sla",
        condition:
          object.sla_response_time_estimated ||
          object.sla_resolution_time_estimated,
      },
      {
        labelKey: "sla.label.actual_time",
        value: [
          object.sla_response_time_actual,
          object.sla_resolution_time_actual,
        ],
        type: "date_time_sla",
        condition:
          object.sla_response_time_estimated ||
          object.sla_resolution_time_estimated,
      },
      {
        labelKey: "sla.label.status",
        value: [
          i18n.t(
            `sla.statuses.${object.sla_response_time_status || "unknown"}`
          ),
          i18n.t(
            `sla.statuses.${object.sla_resolution_time_status || "unknown"}`
          ),
        ],
        type: "plain_sla",
        condition:
          object.sla_response_time_estimated ||
          object.sla_resolution_time_estimated,
      },
    ];
  }

  static tabs = [
    {
      titleKey: "template.detail",
      component: "TemplatesDetailTab",
      input_data(context) {
        return {
          object: context.object,
          templatable_id: context.object.service_id,
          properties: context.object.properties,
          template_assignments: context.object.template_assignments?.filter(
            (ta) => ta.for_checklist === false
          ),
        };
      },
      disabled: (context) => context.dataShow_disableUpdate,
    },
    {
      titleKey: "issue.label.timeline",
      permission: "issues_tab_timeline",
      component: "TimelineTab",
      disabled: () => false,
    },
    {
      titleKey: "issue.label.transactions",
      permission: "issues_tab_transactions",
      component: "TransactionsTab",
      disabled: () => false,
    },
    {
      titleKey: "document.documents",
      permission: "issues_tab_documents",
      component: "DocumentsTab",
      disabled: () => false,
    },
    {
      titleKey: "issue_note.issue_notes",
      permission: "issues_tab_notes",
      component: "IssueNotesTab",
      input_data: (context) => context.object,
      disabled: () => false,
    },
    {
      titleKey: "issue_assignee.issue_resources",
      permission: "issues_tab_persons",
      component: "IssueResourcesTab",
      disabled: () => false,
    },
    {
      titleKey: "asset.assets",
      permission: "issues_tab_assets",
      component: "AssetsIssuesTab",
      input_data: (context) => ({
        assets_issues: context.object.assets_issues,
        service_id: context.object.service_id,
        template_assignments: context.object.template_assignments?.filter(
          (ta) => ta.for_checklist === true
        ),
      }),
      disabled: () => false,
    },
    {
      titleKey: "work_report.work_reports",
      permission: "issues_tab_work_reports",
      component: "WorkReportsTab",
      input_data: (context) => context.object,
      disabled: () => false,
    },
    {
      titleKey: "location.location",
      permission: "issues_tab_locations",
      component: "LocationsTab",
      input_data: (context) => context.object,
      disabled: () => false,
    },
  ];
}

export default Issue;
