import _DatabaseModel, { i18n } from "./_DatabaseModel";
import { toSnakeCase } from "../mixins/HelperFunctions";

class Report extends _DatabaseModel {
  static model = "report";
  static editDialogComponent = "reports-edit-dialog";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { textKey: "report.label.target_entity", value: "target_entity" },
  ];

  static show = {
    buttons: ["edit", "generate_report", "download"],
    submenuActions: ["duplicate", "activity", "destroy"],
    reloadingModels: [],
  };

  static filter = {
    parts: {
      fields: [
        "name",
        "description",
        "report_type",
        "target_entity",
        "time_filter",
      ],
      extended_fields: [],
      time_filter: ["created_at", "updated_at"],
    },
  };

  static report_types = ["single_entity", "data_set"];

  static target_entities = [
    "Issue",
    "Asset",
    "Person",
    "PriceList",
    "Audit",
    "PrimaryAsset",
    "AssetGroup",
  ];

  static cardFields(object) {
    return {
      title: object.name,
      subtitle: i18n.t(
        `${toSnakeCase(object.target_entity)}.${toSnakeCase(
          object.target_entity
        )}`
      ),
    };
  }

  static basicInfo(object) {
    return [
      {
        labelKey: "report.label.report_type",
        value: i18n.t(`report.report_types.${object.report_type}`),
        type: "plain",
      },
      {
        labelKey: "report.label.target_entity",
        value: i18n.t(
          `${toSnakeCase(object.target_entity)}.${toSnakeCase(
            object.target_entity
          )}`
        ),
        type: "plain",
      },
      {
        labelKey: "report.label.contains_avatar",
        value: i18n.t(`generic.${object.definition?.avatar || false}`),
        type: "plain",
        condition:
          object.target_entity === "Asset" || object.target_entity === "Person",
      },
      {
        labelKey: "report.label.contains_qr",
        value: i18n.t(`generic.${object.definition?.qr || false}`),
        type: "plain",
        condition: object.target_entity === "Asset",
      },
      {
        labelKey: "form.label.created_at",
        value: object.created_at,
        type: "date_time",
      },
    ];
  }
  static tabs = [this.detailTab];
}

export default Report;
