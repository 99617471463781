import { defineStore } from "pinia";
import axios from "axios";

export const useLoadableStore = defineStore("loadable", {
  state: () => ({
    loaded: {},
    asset_categories: [],
    audits: [],
    automated_actions: [],
    categories: [],
    connectors: [],
    consequences: [],
    contracts: [],
    controls: [],
    dashboard_screens: [],
    data_sets: [],
    external_emails: [],
    fields: [],
    holidays: [],
    issue_priorities: [],
    issue_state_transitions: [],
    issue_states: [],
    kpis: [],
    likelihoods: [],
    mappers: [],
    person_categories: [],
    person_relations: [],
    price_items: [],
    price_lists: [],
    primary_assets: [],
    reports: [],
    risk_levels: [],
    role_issue_state_transitions: [],
    roles: [],
    rules: [],
    services: [],
    snippets: [],
    standard_soas: [],
    standards: [],
    tag_definitions: [],
    threats: [],
    translations: [],
    vulnerabilities: [],
    warehouses: [],
  }),
  getters: {},
  actions: {
    fetchItems(modelPl) {
      if (!this[modelPl]) return;

      if (!this.loaded[modelPl]) {
        this.loaded[modelPl] = true;
        axios.get(`${modelPl}/load`).then(
          (response) => {
            this[modelPl] = response.data.objects;
          },
          () => {
            this[modelPl] = [];
            this.loaded[modelPl] = false;
          }
        );
      }
    },
    resetItems(modelPl) {
      if (!this[modelPl]) return;

      this[modelPl] = [];
      this.loaded[modelPl] = false;
      this.fetchItems(modelPl);
    },
  },
});
