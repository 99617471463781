import { defineStore } from "pinia";
import { useSettingsStore } from "./settings";
import { UNCHECKED_PARTS, LICENSED_PARTS } from "../../_config/constants";
import { isConfigurationModel, pluralize } from "../mixins/HelperFunctions";

export const useAuthUserStore = defineStore("authUser", {
  state: () => ({
    loaded: false,
    name: "",
    email: "",
    preferences: {},
    permissions: {},
    eula_accepted_at: null,
    otp_required_for_login: false,
    session_id: null,
    person_id: null,
    avatar_id: null,
    category_id: null,
    impersonator: null,
  }),
  getters: {
    avatar: (state) => ({
      document_id: state.avatar_id,
      category_id: state.category_id,
    }),
    isImpersonated: (state) => !!state.impersonator,
    canSeeAppPart: (state) => {
      return (part) => {
        const settingsStore = useSettingsStore();
        if (part === "account" && !!settingsStore.aad) return false;
        if (!part || UNCHECKED_PARTS.includes(part)) return true;
        let licensed = true;
        const license_name = LICENSED_PARTS[part];
        if (license_name && settingsStore.license_parts) {
          // licensed part and license loaded
          licensed = settingsStore.license_parts.includes(license_name);
        }
        // user has permission
        const permitted = state.permissions.parts.includes(part);
        return licensed && permitted;
      };
    },
    canSeeOneOfAppParts: (state) => {
      return (parts) => {
        if (!parts) return true;
        for (const item of parts) {
          if (state.canSeeAppPart(item)) return true;
        }
        return false;
      };
    },
    canCreate: (state) => {
      return (model) => {
        if (
          [
            "contract",
            "service",
            "kpi",
            "warehouse",
            "price_list",
            "automated_action",
            "role",
          ].includes(model)
        )
          return state.permissions.create.includes(pluralize(model));

        if (isConfigurationModel(model)) return state.permissions.configuration;

        return true;
      };
    },
  },
  actions: {},
});
