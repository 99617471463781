import pinia from "../store/pinia";
import qs from "qs";
import { useSettingsStore } from "../../_generic/stores/settings";
import { useLoadableStore } from "../../_generic/stores/loadable";
import { useAppStateStore } from "../../_generic/stores/appState";
import axios from "axios";
import { ScopeSet } from "@azure/msal-common";
import Vue from "vue";

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "brackets" });
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.response.data.reason === "jwt") {
      const settingsStore = useSettingsStore(pinia);
      if (settingsStore.aad && Vue.prototype.$msal.isAuthenticated()) {
        const scopes = new ScopeSet([settingsStore.aad.scope]);
        const token = await Vue.prototype.$msal.acquireToken(scopes);
        await axios.post("login", { aad_access_token: token.accessToken });
        return axios(originalRequest);
      }
      const loadableStore = useLoadableStore(pinia);
      loadableStore.$reset();
      const appStateStore = useAppStateStore(pinia);
      appStateStore.$reset();
      window.location.href = "/#/public/auth/login";
    }
    return Promise.reject(error);
  }
);

export default axios;
