import Vue from "vue";
import Vuetify from "vuetify/lib";
import { Resize } from "vuetify/lib/directives";
import VueChartkick from "vue-chartkick";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import "moment/dist/locale/sk";
import linkify from "vue-linkify";
import App from "../../_generic/App.vue";
import router from "../router/index";
import pinia from "../store/pinia";
import i18n from "../../_generic/translations/i18n";
import VueDragDrop from "vue-drag-drop";
import "vuelayers/dist/vuelayers.css"; // needs css-loader
import VueShortKey from "vue-shortkey";

import { PiniaVuePlugin } from "pinia";
import { useSettingsStore } from "../../_generic/stores/settings";
import axios from "../http/index";

import en from "vuetify/lib/locale/en";
import sk from "vuetify/lib/locale/sk";

const isDev = process.env.NODE_ENV !== "production";
Vue.config.performance = isDev;
Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
Vue.use(Vuetify, { directives: { Resize } });
Vue.use(VueChartkick);
Vue.use(VueMoment, { moment });
Vue.directive("linkified", linkify);
Vue.use(VueDragDrop);
Vue.use(VueShortKey, { prevent: ["input", "textarea"] });

Vue.prototype.$http = axios;

document.addEventListener("DOMContentLoaded", async () => {
  const response = await axios.get(`implementation/all_settings`);
  const { settings, translations } = response.data;
  const settingsStore = useSettingsStore(pinia);
  settingsStore.$patch(settings);

  if (settings.aad) {
    const msal = await import("msal-vue");
    Vue.use(msal.MsalPlugin, {
      auth: {
        clientId: settings.aad.client_id,
        authority: `https://login.microsoftonline.com/${settings.aad.tenant_id}`,
      },
      cache: { cacheLocation: "localStorage" },
    });
  }

  const customEn = {};
  const customSk = {};
  translations.forEach((t) => {
    customEn[`custom.${t.key}`] = t.en || "";
    customSk[`custom.${t.key}`] = t.sk || "";
  });
  i18n.mergeLocaleMessage("en", customEn);
  i18n.mergeLocaleMessage("sk", customSk);

  const themes = getThemes(settings.brand);
  const vuetifyOpts = {
    theme: {
      options: { customProperties: true },
      themes: themes,
    },
    lang: {
      locales: { en, sk },
      current: "sk",
    },
    icons: { iconfont: "mdiSvg" },
  };

  new Vue({
    el: "#app",
    router: router,
    components: { App },
    vuetify: new Vuetify(vuetifyOpts),
    i18n,
    pinia,
  });
});

function getThemes(brand) {
  if (brand === "SPC")
    return {
      light: {
        primary: "#55748B",
        secondary: "#ffffff",
        background: "#F5F6FA",
        success: "#4caf50",
        warning: "#fb8c00",
        error: "#ff5252",
        info: "#2196f3",
      },
      dark: {
        primary: "#55748B",
        secondary: "#ffffff",
        background: "#253746",
        card: "#374D5E",
        menu: "#1e2c37",
        text: "#ffffff",
        anchor: "#ffffff",
        success: "#4caf50",
        warning: "#fb8c00",
        error: "#ff5252",
        info: "#2196f3",
      },
    };

  if (brand === "ASM")
    return {
      light: {
        primary: "#51B2B4",
        secondary: "#ffffff",
        background: "#F5F6FA",
        success: "#4caf50",
        warning: "#fb8c00",
        error: "#ff5252",
        info: "#2196f3",
      },
      dark: {
        primary: "#51B2B4",
        secondary: "#ffffff",
        background: "#224b4c",
        card: "#2d6566",
        menu: "#193838",
        text: "#ffffff",
        anchor: "#ffffff",
        success: "#4caf50",
        warning: "#fb8c00",
        error: "#ff5252",
        info: "#2196f3",
      },
    };

  return {
    light: {
      primary: "#8E39C2",
      secondary: "#ffffff",
      background: "#F5F6FA",
      card: "#ffffff",
    },
    dark: {
      primary: "#8E39C2",
      secondary: "#eeeeee",
      background: "#1A1030",
      card: "#2C2145",
      menu: "#150B2B",
      text: "#eeeeee",
      anchor: "#eeeeee",
    },
  };
}
