const genericAlertM = {
  verb: "bol",
  create: "vytvorený",
  update: "upravený",
  archive: "archivovaný",
  unarchive: "obnovený",
  destroy: "vymazaný",
  new: "Nový",
  expired: "vypršaný",
};

const genericAlertF = {
  verb: "bola",
  create: "vytvorená",
  update: "upravená",
  destroy: "vymazaná",
  archive: "archivovaná",
  unarchive: "obnovená",
  new: "Nová",
  expired: "vypršaná",
};

const genericAlertN = {
  verb: "bolo",
  create: "vytvorené",
  update: "upravené",
  destroy: "vymazané",
  archive: "archivované",
  unarchive: "obnovené",
  new: "Nové",
  expired: "vypršané",
};

const sk = {
  action: {
    edit_state: "Zmeniť stav",
    update: "Upraviť",
    load: "Načítať",
    export: "Exportovať",
    back: "Späť",
    next: "Ďalej",
    select: "Vybrať",
    cancel: "Zrušiť",
    create: "Vytvoriť",
    save: "Uložiť",
    save_new: "Uložiť nový",
    edit: "Editovať",
    archive: "Archivovať",
    unarchive: "Obnoviť",
    manage_subscription: "Správa odberov",
    delete: "Vymazať",
    destroy: "Vymazať",
    remove_personal_data: "Odstrániť osobné údaje",
    remove: "Odstrániť",
    close: "Zavrieť",
    duplicate: "Vytvoriť kópiu",
    all: "Všetky",
    apply: "Aplikovať",
    link: "Nalinkovať",
    impersonate: "Vydávať sa za",
    add_text: "Pridať {type}",
    add_material: "Pridať materiál",
    add_work: "Pridať prácu",
    add_transport: "Pridať dopravu",
    add_note: "Pridať poznámku",
    add_transition: "Pridať prechod",
    create_threat: "Vytvoriť hrozbu",
    create_vulnerability: "Vytvoriť zraniteľnosť",
    create_control: "Vytvoriť opatrenie",
    add_field: "Pridať pole",
    add_section: "Pridať sekciu",
    add_provision: "Pridať ustanovenie",
    create_price_item: "Vytvoriť položku",
  },
  app_parts: {
    about: "O aplikácii",
    account: "Účet",
    activities: "Aktivity",
    administration: "Administrácia",
    asset_categories: "Kategórie majetku",
    asset_groups: "Podporné aktíva",
    assets: "Majetok",
    assets_issues: "Majetok na hláseniach",
    assets_tab_calendar: "Záložka Kalendár",
    assets_tab_documents: "Záložka Dokumenty",
    assets_tab_external_guids: "Záložka Identifikátory",
    assets_tab_issues: "Záložka Hlásenia",
    assets_tab_locations: "Záložka Poloha",
    assets_tab_network_graph: "Záložka Sieťový graf",
    assets_tab_persons: "Záložka Osoby",
    assets_tab_services: "Záložka Služby",
    assets_tab_timeline: "Záložka Časová os",
    assets_tab_work_reports: "Záložka Výkazy práce",
    audit_tab_soa: "SOA Tab",
    audit_tab_valuation: "Záložka Požiadavky",
    audits: "Audity",
    audits_tab_documents: "Záložka Dokumenty",
    automated_actions: "Automatické akcie",
    automated_actions_tab_definition: "Záložka Definícia",
    fields_tab_usage: "Záložka Použitie",
    automated_actions_tab_run: "Záložka Spustenia",
    background_jobs: "Práce na pozadí",
    branding: "Branding",
    categories: "Kategórie",
    categories_tab_templates: "Záložka Šablóny",
    checklists: "Kontrolné zoznamy",
    connectors: "Konektory",
    connectors_tab_run: "Záložka Spustenia",
    contracts: "Zmluvy",
    contracts_tab_documents: "Záložka Dokumenty",
    controls_management: "Riadenie opatrení",
    dashboard_screen_tab_visualization: "Záložka Vizualizácia",
    dashboard_screens: "Dashboardy",
    data_sets: "Filtre",
    data_sets_tab_definition: "Záložka Definícia",
    devices: "Zariadenia",
    document_settings: "Nastavenia dokumentov",
    documents: "Dokumenty",
    documents_tab_entity: "Záložka Priradené objekty",
    standby_plan: "Plán pohotovostí",
    external_emails: "Externé emaily",
    external_emails_tab_issues: "Záložka Hlásenia",
    fields: "Polia",
    health_check: "Kontrola konzistencie",
    holidays: "Sviatky",
    consequences: "Dopady",
    implementation: "Implementácia",
    import: "Import",
    import_assets: "Import majetku",
    import_contracts: "Import zmlúv",
    import_documents: "Import dokumentov",
    import_fields: "Import polí",
    import_holidays: "Import sviatkov",
    import_issues: "Import hlásení",
    import_controls: "Import opatrení",
    import_persons: "Import osôb",
    import_price_items: "Import položiek",
    import_price_lists: "Import cenníkových položiek",
    import_rules: "Import pravidiel",
    import_services: "Import služieb",
    import_standards: "Import noriem",
    import_tag_definitions: "Import tagov",
    import_templates: "Import šablón",
    import_threats: "Import hrozieb",
    import_translations: "Import prekladov",
    import_users: "Import používateľov",
    import_issue_priorities: "Import priorít hlásení",
    import_issue_states: "Import stavov hlásení",
    import_person_relations: "Import vzťahov osôb",
    import_vulnerabilities: "Import zraniteľností",
    import_warehouses: "Import skladových položiek",
    installation: "Inštalácia",
    issue_priorities: "Priority hlásení",
    issue_states: "Stavy hlásení",
    issues: "Hlásenia",
    issues_tab_assets: "Záložka Majetok",
    issues_tab_documents: "Záložka Dokumenty",
    issues_tab_locations: "Záložka Poloha",
    issues_tab_notes: "Záložka Poznámky",
    issues_tab_persons: "Záložka Osoby",
    issues_tab_timeline: "Záložka Časová os",
    issues_tab_transactions: "Záložka Transakcie",
    issues_tab_work_reports: "Záložka Výkazy práce",
    kpis: "KPI",
    kpis_tab_data: "Záložka Dáta",
    kpis_tab_definition: "Záložka Definícia",
    kpis_tab_preview: "Záložka Náhľad",
    license: "Licencia",
    mail_server: "Emailový server",
    main_assets: "Majetok",
    main_audits: "Audity",
    main_connectors: "Konektory",
    main_dashboard_screens: "Dashboardy",
    main_documents: "Dokumenty",
    main_issues: "Hlásenia",
    main_persons: "Osoby",
    main_risk_management: "Riadenie rizík",
    main_services: "Služby",
    main_warehouses: "Sklady",
    maintenance_plan: "Plán údržby",
    mappers: "Priradenia",
    mappers_tab_definition: "Záložka Definícia",
    mappers_tab_run: "Záložka Spustenia",
    controls: "Opatrenia",
    mobile_app: "Mobilná aplikácia",
    notice: "Oznam",
    notifications: "Notifikácie",
    permissions: "Oprávnenia",
    person_relations: "Vzťahy osôb",
    person_categories: "Kategórie osôb",
    persons: "Osoby",
    persons_tab_assets: "Záložka Majetok",
    persons_tab_calendar: "Záložka Kalendár",
    persons_tab_documents: "Záložka Dokumenty",
    persons_tab_external_guids: "Záložka Identifikátory",
    persons_tab_issues: "Záložka Hlásenia",
    persons_tab_work_reports: "Záložka Výkazy práce",
    linkages_setting: "Nastavenie väzieb",
    planning_calendar: "Plánovací kalendár",
    preferences: "Preferencie",
    price_items: "Položky",
    price_items_tab_external_guids: "Záložka Identifikátory",
    price_items_tab_warehouses: "Záložka Sklady",
    price_lists: "Cenníky",
    price_lists_tab_items: "Záložka Položky",
    primary_assets: "Primárne aktíva",
    likelihoods: "Pravdepodobnosti",
    profile: "Profil",
    public_form: "Verejný formulár pre nahlasovanie",
    reminders: "Pripomienky",
    reports: "Reporty",
    risk_management: "Riadenie rizík",
    risk_levels: "Úrovne rizík",
    risks: "Analýza rizík",
    categories_tab_threats: "Záložka Hrozby",
    threats_tab_vulnerabilities: "Záložka Zraniteľnosti",
    primary_asset_tab_asset_groups: "Záložka Podporné Aktíva",
    asset_group_tab_assets: "Záložka Aktíva",
    asset_group_tab_controls: "Záložka Opatrenia",
    vulnerability_tab_controls: "Záložka Opatrenia",
    roles: "Roly",
    roles_tab_issue_state_transitions: "Záložka Workflow",
    roles_tab_permissions: "Záložka Oprávnenia",
    roles_tab_persons: "Záložka Osoby",
    roles_tab_role: "Záložka Rola",
    rules: "Pravidlá",
    rules_tab_assets: "Záložka Majetok",
    rules_tab_documents: "Záložka Dokumenty",
    services: "Služby",
    services_tab_assets: "Záložka Majetok",
    services_tab_documents: "Záložka Dokumenty",
    services_tab_issues: "Záložka Hlásenia",
    services_tab_templates: "Záložka Šablóny",
    services_tab_persons: "Záložka Osoby",
    services_workflow_tab: "Záložka Workflow",
    services_tab_sla: "Záložka SLA",
    settings: "Nastavenia",
    snippets: "Preddefinované texty",
    soa_tab_controls: "Záložka Opatrenia",
    soas: "Vyhlásenia o aplikovateľnosti",
    standard_soas: "Vyhlásenia o aplikovateľnosti",
    standard_soa_tab_valuation: "Záložka Požiadavky",
    standards: "Normy",
    statistics: "Štatistika",
    subscriptions: "Odbery",
    subscriptions_tab_subscribed_items: "Záložka Odoberané položky",
    tag_definitions: "Tagy",
    threats: "Hrozby",
    translations: "Preklady",
    user_settings: "Predvolené nastavenia používateľov",
    users: "Používatelia",
    vulnerabilities: "Zraniteľnosti",
    warehouses: "Sklady",
    warehouses_tab_balance: "Záložka Stav",
    warehouses_tab_external_guids: "Záložka Identifikátory",
    warehouses_tab_transactions: "Záložka Transakcie",
  },
  form: {
    hint: {
      global_search: "Hľadať podľa názvu...",
    },
    placeholder: {
      date: "RRRR-MM-DD",
      time: "HH:MM",
    },
    label: {
      acceptable: "Akceptovateľná",
      activity_type: "Typ aktivity",
      activity: {
        entity_id: "ID objektu",
        entity_type: "Typ objektu",
        person_id: "Autor osoba",
        connector_id: "Autor konektor",
      },
      aggregate: "Agregácia",
      apply: "Aplikovať",
      apply_from: "Aplikovať od",
      apply_to: "Aplikovať do",
      archive_all: "Archivovať objekt so všetkými podradenými položkami",
      archived_at: "Archivované",
      asset_category_id: "Kategória majetku",
      asset_resource_id: "Majetok (Zdroj)",
      asset_id: "Majetok",
      asset_with_children: "S podradenými položkami",
      assignee: "Priradená osoba",
      attempts: "Pokusy",
      audit_id: "Audit",
      author: "Autor",
      author_system: "Autor systém",
      auto_center: "Automatické centrovanie",
      automated_action_id: "Autor automatická akcia",
      automated_action_type: "Typ automatickej akcie",
      availability: "Dostupnosť",
      blocked: "Blokovaný",
      budget: "Náklady",
      calculation: "Spôsob výpočtu",
      cancel: "Zrušiť",
      category_id: "Kategória",
      change_parent: "Zmeniť rodiča všetkým podradeným položkám",
      character: "Písm.",
      closed_at: "Uzavreté dňa",
      code_1: "Kód 1",
      code_2: "Kód 2",
      column_filter: "Filter stĺpcov",
      confidentiality: "Dôvernosť",
      connector_id: "Konektor",
      contract_id: "Zmluva",
      contract_type: "Typ zmluvy",
      count: "Počet",
      created_at: "Vytvorené",
      created_by: "Vytvoril",
      created_from: "Vytvorené od",
      created_to: "Vytvorené do",
      creator_id: "Autor osoba",
      currency: "Mena",
      current_hint: "0 znamená súčasný",
      customer_id: "Zákazník",
      dashboard_screen_id: "Dashboard",
      data_set_id: "Filter",
      data_type: "Dátový typ",
      date_from: "Dátum od",
      date_to: "Dátum do",
      description: "Popis",
      detail: "Pole v detaile",
      detail_on_asset: "Pole v detaile majetku",
      display: "Zobrazenie",
      effective_at: "Účinná dňa",
      email: "Email",
      en: "Preklad EN",
      entity_id: "ID vzťahu",
      entity_type: "Typ objektu",
      error: "Chyba načítania filtra",
      evidence: "Dôkazy",
      expense: "Výdaj",
      external_email_id: "Autor externý email",
      failed_at: "Zlyhané",
      field: "Zoskupiť podľa",
      file: "Súbor",
      file_size_range: "Veľkosť",
      files: "Súbory",
      findings: "Zistenia",
      fixed: "Fixné",
      from: "Od",
      gdpr_privacy_policy: "Podmienky ochrany osobných údajov",
      gtin: "GTIN",
      id: "ID",
      identifier: "Identifikátor",
      information_unavailable: "Informácia nedostupná",
      integrity: "Integrita",
      issue: {
        person_id: "Priradená osoba",
      },
      issue_priority_id: "Priorita",
      issue_state_id: "Stav",
      kpi_id: "KPI",
      key: "Kľúč",
      last: "Posledné",
      mapper_id: "Priradenie",
      maximum: "Maximum",
      control_id: "Opatrenie",
      minimum: "Minimum",
      name: "Názov",
      new_parent: "Presunúť pod rodiča",
      no_value_option_asset: "Zobraziť majetky, v ktorých sa nenachádza {type}",
      no_value_option_asset_risk:
        "Zobraziť riziká, v ktorých sa nenachádza {type}",
      no_value_option_document:
        "Zobraziť dokumenty, v ktorých sa nenachádza {type}",
      no_value_option_issue:
        "Zobraziť hlásenia, v ktorých sa nenachádza {type}",
      no_value_option_person: "Zobraziť osoby, v ktorých sa nenachádza {type}",
      no_value_option_requirement:
        "Zobraziť požiadavky, v ktorých sa nenachádza {type}",
      not_planned: "Nenaplánované",
      offset: "Posun",
      on: "Spustená",
      operations: "Operácie",
      operations_header: "Operácie nad vybranými objektmi",
      order: "Objednávka",
      organization_id: "Organizácia",
      otp_required_for_login: "Dvojfaktorová autentifikácia",
      overlap: "Prekrývať",
      paragraph: "§",
      parent_id: "Patrí pod",
      period: "Perióda",
      period_filter_method: "Spôsob filtrovania obdobia",
      persistence: "Existencia",
      person_relation_id: "Vzťah",
      person_category_id: "Kategória osôb",
      person_id: "Osoba",
      picture: "Obrázok",
      point: "Bod",
      price_list_id: "Cenník",
      price_type: "Typ položky",
      primary_asset_id: "Primárne aktívum",
      public: "Verejný",
      public_token_valid: "Iba verejné",
      range: "Rozsah",
      receipt: "Príjem",
      relation_type_id: "ID vzťahu",
      report_id: "Report",
      report_type: "Typ reportu",
      resolve_at: "Vyriešiť dňa",
      risk_reduction: "Zníženie rizika",
      risk_level_id: "Úroveň rizika",
      role_id: "Rola",
      row: "Riadok",
      rule_id: "Pravidlo",
      run_at: "Spustiť",
      run_type: "Typ spustenia",
      service_id: "Služba",
      service_organization_id: "Servisná organizácia",
      service_type: "Typ služby",
      show_legend: "Zobraziť legendu",
      sk: "Preklad SK",
      solution: "Riešenie",
      solve_status: "Stav riešenia",
      sort: "Zoradiť podľa",
      sort_desc: "Zoradiť zostupne",
      standard_id: "Norma",
      standard_soa_id: "Vyhlásenie o aplikovateľnosti",
      start_of_working_hours: "Začiatok pracovného času",
      state: "Stav",
      subscription_type: "Typ odberu",
      subsection: "Ods.",
      sum: "Súčet",
      table_headers: "Stĺpce tabuľky",
      tag_definitions: "Tagy",
      tag_definition_id: "Tag",
      target_entity: "Cieľový dátový typ",
      threat_id: "Hrozba",
      tier: "Typ používateľa",
      till_now: "až po súčasnosť",
      time_filter: "Dátumové pole",
      time_from: "Čas od",
      time_to: "Čas do",
      to: "Do",
      transfer: "Presun",
      type: "Typ",
      unit: "Jednotka",
      updated_at: "Naposledy upravené",
      valid_at: "Platné dňa",
      valuation: "Hodnotenie",
      value: "Hodnota",
      value_in_detail: "Hodnota v detaile",
      visualization: "Spôsob zobrazenia",
      vulnerability_id: "Zraniteľnosť",
      warehouse_id: "Sklad",
      with_user: "S používateľom",
      source_warehouse_id: "Zdrojový sklad",
      target_warehouse_id: "Cieľový sklad",
      price_item_id: "Položka",
      whole: "Celé",
      with_children: "S podradenými položkami",
      working_color: "Farba ikony pre majetok bez hlasení",
      working_hide: "Nezobrazovať majetok bez hlásení",
      xtitle: "Popis osi X",
      ytitle: "Popis osi Y",
      zoom: "Priblíženie",
    },
    error: {
      required: "Toto pole je povinné",
      valid: "Toto pole nie je platné",
      length_min_max: "Musí obsahovať minimálne {min} a maximálne {max} znakov",
      length_min: "Musí obsahovať minimálne {min} znakov",
      length_max: "Musí obsahovať maximálne {max} znakov",
      json: "Musí obsahovať platný JSON",
      value_min_max: "Hodnota musí byť minimálne {min} a maximálne {max}",
      value_min: "Hodnota musí byť minimálne {min}",
      value_max: "Hodnota musí byť maximálne {max}",
      value_higher: "Hodnota musí byť väčšia ako {value}",
      value_lower: "Hodnota musí byť menšia ako {value}",
      value_higher_equal_zero: "Hodnota musí byť rovná alebo väčšia ako 0",
      value_custom_range:
        "Hodnota musí byť rovná alebo väčšia ako {min} a rovná alebo menšia ako {max}",
      value_higher_equal_0_lower_equal_custom_value:
        "Hodnota musí byť rovná alebo väčšia ako 0 a rovná alebo menšia ako {value}",
      not_zero: "Hodnota nesmie byť 0",
      email: "Musí byť platná emailová adresa",
      multi_email: "Musí obsahovať iba platné emailové adresy",
      big_file: "Veľkosť súboru presahuje limit",
      not_in_past: "Dátum nesmie byť v minulosti",
      must_contain_number: "Musí obsahovať aspoň jedno číslo",
      must_contain_lower: "Musí obsahovať aspoň jedno malé pismeno",
      must_contain_upper: "Musí obsahovať aspoň jedno veľké písmeno",
      items_min_max:
        "Počet položiek musí byť minimálne {min} a maximálne {max}",
      later_date: "Dátum musí byť neskôr ako '{prev}'",
      match_password: "Musí sa zhodovať s heslom",
    },
  },
  enum: {
    boolean: {
      no: "Nie",
      yes: "Áno",
    },
    week_first_day: {
      monday: "Pondelok",
      sunday: "Nedeľa",
    },
  },
  other: {
    actions: "Akcie",
    info: "Info",
    summary: "Zhrnutie",
    other: "Iné",
    tags: {
      subscribe: "Zapnuté notifikácie",
      children_count: "Počet podradených položiek",
    },
    basic_info: "Základné informácie",
  },
  first_login: {
    cookies:
      "Som si vedomý / vedomá, že aplikácia používa súbory cookies a súhlasím s tým",
    personal_info_GDPR:
      "Súhlasím s ďalším spracovávaním mojich osobných údajov",
    license: "Súhlasím s EULA",
    accept: "Akceptujem",
    decline: "Neakceptujem",
    welcome: "Vitajte v AUSEMIO",
    link_text: "Viac informácií",
    headers: {
      cookies: "O súboroch cookies na týchto stránkach",
      gdpr: "Zbieranie dát",
    },
  },
  tooltips: {
    navbar_icons: {
      log_out: "Odhlásiť sa",
      locales_switch: "Vybrať jazyk",
      notifications: "Notifikácie",
      documentation: "Dokumentácia",
      search: "Použite # + Id číslo pre vyhľadávanie podľa Id",
      search_shortcut: "Vyhľadať ( S )",
      stop_impersonating: "Prestať sa vydávať za používateľa",
      list: "Zobrazenie zoznamu",
      card: "Zobrazenie kariet",
      tree: "Zobrazenie stromu",
      map: "Zobrazenie mapy",
      cal: "Zobrazenie kalendára",
    },
    show_assets_calendar_tooltip: {
      table_view: "Tabuľka",
      graphic_view: "Plán",
    },
    show_buttons: {
      add: "Vytvoriť nový objekt",
      add_primary_asset_group: "Pridať podporné aktíva",
      add_new_transition: "Pridať nový prechod medzi stavmi",
      add_shortcut: "Vytvoriť nový objekt ( C )",
      show_previous_note: "Zobraziť predchádzajúcu poznámku",
      hide_previous_note: "Skryť predchádzajúcu poznámku",
      apply_plan_shortcut: "Aplikovať plán ( A )",
      apply_controls: "Aplikovať opatrenia",
      new_note: "Vytvoriť novú poznámku",
      reply_to_note: "Odpovedať na {type} poznámku",
      forward_note: "Preposlať",
      copy_link: "Kopírovať odkaz na túto poznámku",
      edit: "Upraviť objekt",
      change_state: "Zmena stavu hlásenia",
      add_location: "Pridať polohu",
      edit_location: "Upraviť polohu",
      new_document: "Vytvoriť a nalinkovať nový dokument",
      import: "Importovať zo súboru",
      new_material_report: "Nový výkaz materiálu",
      new_work_report: "Nový výkaz práce",
      new_transport_report: "Nový výkaz dopravy",
      entity_link: "Nalinkovať objekt",
      link_document: "Nalinkovať existujúci dokument",
      show_in_documents: "Otvoriť v Dokumentoch",
      show_in_issues: "Otvoriť v Hláseniach",
      run_applying: "Spustiť aplikovanie opatrenia",
      add_new_assignee: "Pridať novú osobu",
      passwd_change: "Zmeniť heslo",
      other: "Ostatné akcie",
      new_option: "Nová možnosť",
      add_issue: "Vytvoriť hlásenie",
      add_floor_plan: "Pridať pôdorys",
      edit_floor_plan: "Upraviť pôdorys",
      operations: "Operácie ( O )",
      filter: "Filtrovať ( F )",
      filter_inside: "Filtrovať ( Shift + Enter )",
      filter_actions: "Akcie filtra",
      filter_config: "Konfigurácia filtra",
      clear_filter_shortcut: "Zmazať filter ( Shift + Backspace )",
      apply: "Aplikovať",
      setup_permissions: "Nastaviť oprávnenia",
      navigate: "Navigovať",
      download: "Stiahnuť",
      use_translation: "Použiť preklad",
      use_optional_translation: "Použiť text bez prekladu",
      add_DataSet_dashlet: "Pridať dlaždicu typu filter",
      add_Kpi_dashlet: "Pridať dlaždicu typu KPI",
      add_Calendar_dashlet: "Pridať dlaždicu typu kalendár",
      find_by_qr: "Nájdi majetok podľa BAR/QR kódu",
      add_threat: "Vytvoriť a pridať novú hrozbu",
      add_vulnerability: "Vytvoriť a pridať novú zraniteľnosť",
      add_control: "Vytvoriť a pridať nové opatrenie",
      display_hidden: "Zobrazí skryté hlásenia",
      add_section: "Pridať novú sekciu",
      add_provision: "Pridať nové ustanovenie",
      add_price_item: "Pridať novú položku",
      create_template_assignment: "Vytvoriť šablónu",
      apply_planned_control: "Aplikovať planované opatrenie",
      disabled_buttons: {
        add: "Nemáte oprávnenie na vytvorenie nového objektu",
        add_new_transition:
          "Nemáte oprávnenie pridať nový prechod medzi stavmi",
        new_note: "Nemáte oprávnenie na vytvorenie novej poznámky",
        edit: "Nemáte oprávnenie na úpravu objektu",
        new_location: "Nemáte oprávnenie na vytvorenie novej lokality",
        generic: "Nemáte oprávnenie na túto akciu",
        fields_echo: "Nemáte zadané všetky polia",
        no_filter: "Nemáte aktívny žiadny filter",
        no_issue: "Nie je potrebné nové hlásenie",
        no_warehouse_items: "Táto položka nie je na sklade",
        warehouse_delete: "Zostatok a objednané musia byť 0",
        no_batches: "Položka nemá šarže",
        no_layer: "Nemáte pôdorys",
        reminder_exp: "Pripomenutie vypršalo",
      },
    },
    tabs_icons: {
      search: "Vyhľadať",
      save: "Uložiť",
      delete: "Zmazať",
      edit: "Upraviť",
      priority_move_up: "Zvýšiť prioritu",
      priority_move_down: "Znížiť prioritu",
      move_up: "Posunúť hore",
      move_down: "Posunúť dolu",
      checklist_preview: "Náhľad kontrolného z.",
      empty: "Nie je zadefinovaný tooltip !!!",
      add: "Pridať",
      preview: "Náhľad",
      detail: "Zobraziť detail",
      show_in_cal: "Zobraziť všetky zdroje v kalendári",
      history_of_asset_issues: "Zobraziť históriu hlásení na tomto majetku",
    },
  },
  generic: {
    application: "Aplikácia",
    archive: "Archivácia",
    back_to_login: "Späť na prihlásenie",
    camera: "Kamera",
    code: "Kód",
    conf_new_passwd: "Potvrdiť nové heslo",
    drop_files_message: "Nahrať súbory presunutím",
    error_value: "Chybný údaj",
    false: "Nie",
    filter_active: "Filter je aktívny",
    forgot: "Zabudli ste heslo?",
    item: "Položka",
    log_in: "Prihlásiť",
    log_in_header: "Prihlásenie",
    message: "Správa",
    new_passwd: "Nové heslo",
    object: "Objekt",
    others: "iných",
    password: "Heslo",
    copyright: "Copyright © 2023",
    reader: "Čítačka",
    run: "Spustiť",
    scan: "Skenovať",
    scan_qr: "Skenovať QR/BAR Kód",
    send_reset: "Obnoviť",
    set_passwd: "Nastaviť nové heslo",
    show_less: "Zobraziť menej",
    show_more: "Zobraziť viac",
    system: "Systém",
    total_count: "Celkový počet",
    true: "Áno",
    two_factor_auth_code: "Overovací kód",
    unknown: "Neznámy",
    unset: "Neurčené",
  },
  date_parts: {
    second: "sekunda",
    minute: "minúta",
    hour: "hodina",
    day: "deň",
    week: "týždeň",
    month: "mesiac",
    year: "rok",
  },
  // TC translations
  day: "1 deň | {n} dni | {n} dní",
  week: "1 týždeň | {n} týždne | {n} týždňov",
  month: "1 mesiac | {n} mesiace | {n} mesiacov",
  year: "1 rok | {n} roky | {n} rokov",
  messages: {
    publicIssue: {
      cameraAllow: "Povoľte kameru pre skenovanie QR kódu",
      noCamera: "Chyba: Kamera nie je dostupná v zariadení",
      httpsNeeded: "HTTPS je potrebné pre skenovanie QR kódu",
      cameraInUse: "Chyba: Nie je kamera práve používaná?",
      cameraFront: "Zariadenie nemá prednú alebo zadnú kameru",
      streamAPI:
        "Chyba: Knižnica na živé vysielanie nie je podporovaná v prehliadači",
    },
    login: {
      success: "Úspešné prihlásenie",
      error: "Nepodarilo sa prihlásiť. Nesprávne meno alebo heslo.",
      error_two_factor: "Nepodarilo sa prihlásiť. Neplaný autentifikačný kód.",
    },
    logout: "Úspešné odhlásenie",
    pageLoadAuthenticationError:
      "Autentifikácia zlyhala. Prosím prihláste sa pre pokračovanie.",
    appPartNoAccess: "Nemáte oprávnenie na túto časť aplikácie",
    passwordReset: {
      error:
        "Chyba nastavenia nového hesla: {errors}. Pre vygenerovanie nového linku na obnovenie hesla kliknite na 'OBNOVIŤ'. Ak sa chyba opakuje, kontaktujte prosím administrátora.",
      success: "Heslo bolo zmenené",
      instructionsSend:
        "Poslali sme Vám odkaz na obnovenie hesla na Váš emailový účet. Postupujte podľa krokov na obnovenie hesla. V prípade nedoručenia prosím skontrolujte spam alebo správnosť zadaného emailu, cez ktorý ste u nás zaregistrovaný.",
      instructionsSendError:
        "Chyba pri posielaní inštrukcií pre resetovanie hesla emailom",
    },
    globalSearchError: "Nie je možné načítať výsledky",
    treeLoadError: "Chyba načítania stromu",
    will_permanently: "bude natrvalo",
    question: "Chcete pokračovať?",
    cant: "Nie je možné",
    noPermissionError: "Nemáte dostatočné oprávnenie.",
    select: "Vyberte",
    noDataListPermissions: "Nemáte oprávnenia na žiadnu položku",
    empty_import_file: "Váš súbor neobsahuje dáta pre import",
    mapTooMuchData:
      "Máte vybraných príliš veľa objektov (viac ako 1000), ktorých súčasné zobrazenie by výrazne zneprehľadnilo a aj spomalilo vizualizáciu na mape. Použite filter pre výber menšej množiny.",
    moreThenAnother: "{firstName} musí byť väčšie ako {secondName}.",
    check: "Pravdepodobne ste nezadali všetky polia správne",
    scanErr: "Neplatný GTIN",
    scanErrorBatch: "Pri tejto naskenovanej položke je povinná šarža",
    notInList: "Položka {name} {batch} nie je na zozname",
    no_icon: "Prosím zvoľte ikonu",
    notInChecklist: "Tento majetok nie je súčasťou tohto kontrolného zoznamu",
    close_transaction:
      "Uzavretie transakcie, pri tomto kroku sa definitívne uzavrie Vaša transakcia, nebude ju možné už zmeniť. ",
    license: {
      user_exceeded:
        "Ďakujeme, že využívate platformu AUSEMIO. Pre pridanie ďalších používateľov, prosím kontaktujte Vášho dodávateľa, prípadne sa na nás obráťte na {web}.",
      connector_exceeded:
        "Ďakujeme, že využívate platformu AUSEMIO. Pre pridanie ďalších konektorov, prosím kontaktujte Vášho dodávateľa, prípadne sa na nás obráťte na {web}.",
    },
    original_parent_archived:
      "Pôvodný rodič je archivovaný, prosím vyberte nového rodiča",
    remove_personal_data:
      "Odstráni osobné údaje v karte osoba a všetky údaje s príznakom osobné údaje v šablónach.",
    personal_data_removed: {
      success: "Osobné údaje boli odstránené.",
      error: "Nie je možné odstrániť osobné údaje.",
    },
    setup_persons: {
      success: "Osoby boli nastavené.",
      error: "Nie je možné nastaviť osoby.",
    },
    impersonate: {
      success: "Práve sa vydávate za používateľa {person}",
    },
  },

  dataTableMessages: {
    filter: {
      name: "Filtrovať položky podľa názvu",
    },
    emptyData: {
      headerPt1: "Nie sú zobrazené dáta pre",
      headerPt2: ", dôvody môžu byť nasledujúce",
      filterReason: "Nastavenému filtru nevyhovujú žiadne dáta",
      emptyDatabaseReason: "V databáze nie su vytvorené dáta",
      permissionReason: "Nemáte oprávnenie na zobrazenie dát ",
      genericEmptyDataMessage:
        "Nie sú vytvorené objekty alebo nemáte oprávnenie na to, aby ste ich videli",
      loading: "Načítavajú sa dáta, prosím počkajte",
      scanner: "Prosím začnite skenovať položky",
      warehouse: "Prosím vyberte sklad",
    },
    bottomNavbar: {
      list: "Zoznam",
      tree: "Strom",
      map: "Mapa",
      card: "Karty",
    },
  },
  sorts: {
    asc: "Vzostupne",
    desc: "Zostupne",
    created_at: {
      asc: "Od najstarších",
      desc: "Od najnovších",
    },
    updated_at: {
      asc: "Dávno",
      desc: "Nedávno",
    },
    discarded_at: {
      asc: "Dávno",
      desc: "Nedávno",
    },
    name: {
      asc: "A - Z",
      desc: "Z - A",
    },
    category: {
      name: {
        asc: "A - Z",
        desc: "Z - A",
      },
    },
    issue_priority_id: {
      asc: "Od najnižšej",
      desc: "Od najvyššej",
    },
  },
  person: {
    person: "Osoba",
    persons: "Osoby",
    acc: "osobu",
    tree: "Strom osôb",
    alert: genericAlertF,
    label: {
      avatar: "Obrázok",
      submenu: {
        new_user: "Vytvoriť používateľa",
      },
      associated_user: "Vytvoriť osobu s pridruženým používateľom",
    },
    hint: {
      roles: "Vyberte roly, ktoré prislúchajú osobe",
    },
  },
  asset: {
    asset: "Majetok",
    assets: "Majetok",
    acc: "majetok",
    tree: "Strom majetku",
    card: "Karty majetku",
    alert: genericAlertM,
    label: {
      avatar: "Obrázok",
      guid: "GUID",
      properties: "Vlastnosti majetku",
      submenu: {
        new_issue: "Vytvoriť hlásenie",
      },
      dependent: "Závislé od",
      depends: "Závisí na",
      view_all: "Zobraziť",
    },
    select_type: "Spôsob výberu majetku",
    asset_filters: "Filtre na majetok",
    search_assets: "Vyhľadať majetok",
  },
  document: {
    document: "Dokument",
    documents: "Dokumenty",
    acc: "dokument",
    label: {
      entity_document: "Priradené objekty",
      dialog_entity_type: "Typ objektu",
      select_objects: "Vyberte objekt",
      less_than_1_mb: "Menej ako 1 MB",
      size: "Veľkosť",
      gallery: "Galéria dokumentov",
      table_view: "Tabuľka",
      gallery_view: "Galéria",
      noPreview: "Ukážka tohto typu súboru nie je k dispozícií",
      preview: "Náhľad",
      public_token_valid: "Verejný",
      attachments: "Prílohy",
      without_relation: "Bez vzťahu",
      submenu: {
        compress: "Komprimovať obrázok",
        new_folder: "Vytvoriť nový priečinok",
        add_doc: "Pridať dokument",
      },
    },
    public_form: {
      sorry: "Nie je možné zobraziť dokument.",
    },
    alert: genericAlertM,
  },
  folder: {
    folder: "Priečinok",
    acc: "priečinok",
    alert: genericAlertM,
    files_location: "Umiestnenie nahrávaných súborov",
  },
  contract: {
    contract: "Zmluva",
    contracts: "Zmluvy",
    acc: "zmluvu",
    alert: genericAlertF,
    contract_types: {
      fixed_prolong: "Zmluva na dobu určitú s automatickým predĺžením",
      fixed_end: "Zmluva na dobu určitú s ukončením",
      indefinite: "Zmluva na dobu neurčitú",
    },
    label: {
      customer: "Zákazník",
      service_organization: "Servisná organizácia",
      contract_type: "Typ zmluvy",
      valid_from: "Platná od",
      effective_from: "Účinná od",
      effective_to: "Účinná do",
      effective_at: "Účinná dňa",
      prolongation_interval: "Interval predĺženia",
      notice_period: "Výpovedná lehota",
      submenu: {},
    },
    hint: {
      customer: "Osoba, ktorá obdrží dodávku služieb",
      service_organization: "Osoba, ktorá zabezpečuje dodávku služieb",
      valid_from: "Začiatok platnosti",
      effective_from: "Začiatok účinnosti",
      effective_to: "Koniec účinnosti a platnosti",
      notice_period: "Obdobie výpovednej lehoty",
      prolongation_interval: "Obdobie o ktoré sa zmluva predĺži",
    },
  },
  service: {
    service: "Služba",
    services: "Služby",
    acc: "službu",
    alert: genericAlertF,
    label: {
      automated_planning: "Automatická úprava plánu revízií",
      planned_revisions: "Počet vygenerovaných plánovaných revízií",
      resolve_plan: "Plánované ukončenie",
      resolve_true: "Skutočné ukončenie",
      assets_revisions: "Revízia majetku",
      service_type: "Typ služby",
      workflow: "Workflow",
      copy_workflow: "Skopírovať workflow",
      copy_templates: "Skopírovať šablóny",
      copy_role_assignments: "Skopírovať priradenia k rolám",
      copy_sla: "Skopírovať SLA",
      copy_permissions: "Skopírovať oprávnenia",
      setup_all: "Výber kontrolného zoznamu pre všetky kategórie",
      submenu: {
        new_issue: "Vytvoriť hlásenie",
        workflow_diagram: "Generovať workflow diagram",
      },
      without: "Bez služby",
      allowed_tags: "Povolené tagy",
    },
    tooltip: {
      no_error: "Žiadna chyba",
      no_issues: "Nie je priradené žiadne hlásenie",
      missing_resolve_at: "Priradené hlásenie bez dátumu riešenia",
    },
    messages: {
      hint: "Diagram bude vygenerovaný na pozadí a súbor bude uložený k dokumentom služby. O dokončení budete informovaný notifikáciou v aplikácii.",
    },
    service_types: {
      periodic: "Opakovaná",
      reactive: "Reaktívna",
    },
    add_assets_dialog: "Nastavenie majetku pre službu",
  },
  service_person: {
    service_person: "Osoba na službe",
    service_persons: "Osoby na službe",
    acc: "osobu na službe",
    alert: genericAlertF,
    label: {
      standby_plan: "Plán pohotovostí",
      recommended_persons: "Odporúčané osoby",
      auto_assign_restrictions: "Obmedzenia automatického priradzovania",
    },
  },
  standby_time: {
    standby_time: "Čas pohotovosti",
    standby_times: "Čas pohotovostí",
    acc: "čas pohotovosti",
    alert: genericAlertM,
  },
  rule: {
    rule: "Pravidlo",
    rules: "Pravidlá",
    acc: "pravidlo",
    alert: genericAlertN,
    label: {
      valid_from: "Platné od",
      valid_to: "Platné do",
      repeat: "Opakovať",
      required_by_law: "Vyžadované legislatívou",
      valid_at: "Platné dňa",
      submenu: {},
    },
    hint: {
      repeat: "Interval medzi jednotlivými prehliadkami",
    },
  },
  calendar: {
    calendar: "Kalendár",
    planning_calendar: "Plánovací kalendár",
    maintenance_plan: "Plán údržby",
    type: "Typ",
    drag: "Presunúť do kalendára",
    drag_tooltip: "Pre presunutie prosím vyberte osobu",
    hours_from: "Vybrané hodiny",
    hours_to: "do",
    week: "Týždeň",
    apply: "Aplikovať plán",
    today: "Dnes",
    label: {
      time_from: "Zobraziť od",
      time_to: "Zobraziť do",
      table_mode: "Tabuľka",
    },
    coloring: {
      label: "Ofarbenie",
      default: "Predvolené",
    },
    types: {
      month: "Mesiac",
      week: "Týždeň",
      day4: "4 dni",
      day: "Deň",
      year: "Rok",
      scheduler: "Plánovač",
    },
    apply_dialog: {
      total: "Celkový počet",
      success: "Úspešne naplánované",
      fail: "Neúspešne naplánované",
      not_changed: "Už naplánované v minulosti",
      source: "Dôvod chyby",
      no_service: "Hlásenie bez služby",
      no_plan_state: "Stav nenakonfigurovaný",
      no_transition: "Prechod nenájdený",
      transition_fail: "Chyba prechodu: {message}",
    },
  },
  issue: {
    issue: "Hlásenie",
    issues: "Hlásenia",
    acc: "hlásenie",
    issues_without_service: "Hlásenia bez služby",
    alert: genericAlertN,
    label: {
      timeline: "Časová os",
      transactions: "Transakcie",
      change_state: "Zmeniť stav",
      create: "Vytvorenie",
      update: "Úprava",
      destroy: "Zmazanie",
      difference: "Rozdiel",
      to: "na",
      identifier: "Identifikátor",
      public_token_valid: "Verejné",
      public_link: "Verejný odkaz",
      planned_at: "Naplánované dňa",
      resolve_at: "Vyriešiť dňa",
      closed_at: "Uzavreté dňa",
      resolve_from: "Vyriešiť od",
      resolve_to: "Vyriešiť do",
      change_state_dialog: "Zmena stavu hlásenia",
      no_state: "Žiaden stav",
      and_more: " a ďalšie {number}",
      solution: "Riešenie",
      display_hidden: "Zobraziť skryté hlásenia",
      sla_response_time_estimated: "Odpoveď - Limit podľa SLA",
      sla_response_time_actual: "Odpoveď - Skutočný čas",
      sla_resolution_time_estimated: "Vyriešenie - Limit podľa SLA",
      sla_resolution_time_actual: "Vyriešenie - Skutočný čas",
      submenu: {
        link_issue: "Spravovať prepojené hlásenia",
        create_reminder: "Vytvoriť pripomienku",
      },
      issues_history: "História hlásení",
      change_service: "Zmena služby",
      requested_service: "Požadovaná služba",
      requested_state: "Požadovaný stav",
    },
    hint: {
      service: "Služba, v rámci ktorej má byť hlásenie riešené",
    },
    public_form: {
      new: "Nové Hlásenie",
      continue: "Ďalej",
      back: "Späť",
      skip: "Preskočiť",
      qr_code: "Porucha na",
      email: "Váš kontaktný email",
      title: "Nahlásenie poruchy",
      title_files: "Máte súbory vzťahujúce sa k poruche?",
      description_files:
        "K Vášmu hláseniu je možné priložiť až 5 fotografií prípadne iných súborov.",
      title_qr: "Je na nahlasovanom  majetku viditeľný QR kód?",
      description_qr:
        "Naskenovanie QR kódu na pokazenom majetku môže pomôcť lepšie identifikovať problém. " +
        "Ak nevidíte žiadny QR kód alebo Vaše zariadenie nemá fotoaparát, môžete tento krok preskočit.",
      title_contact: "Kontakt na Vás",
      location_header: "Chcete uložiť presnú polohu hlásenia?",
      description_location:
        "Uloženie aktuálnej polohy nám môže pomôcť rýchlejšie nájsť problém.",
      location_save: "Uložiť aktuálnu polohu",
      location_info:
        "Pre uloženie aktuálnej polohy je potrebné mať v prehliadači povolené zdieľanie polohy. " +
        "V prípade, že ste zdieľanie polohy zakázali a chcete používať polohu " +
        "je potrebné toto nastavenie zmeniť a znovu načítať stránku.",
      description_contact:
        "Nechajte nám prosím kontakt. " +
        "Je potrebný pre prípadnú komunikáciu a informovanie o vyriešení požiadavky.",
      title_thank: "Ďakujeme za Vaše hlásenie",
      description_thank:
        "Stav hlásenia budete môcť sledovať cez odkaz poslaný na email",
      sorry: "Nie je možné zobraziť hlásenie.",
    },
  },
  kpi: {
    kpi: "KPI",
    kpis: "KPI",
    acc: "KPI",
    alert: genericAlertN,
    label: {
      computed_at: "Vypočítané o",
      refresh_rate: "Perióda obnovenia",
      data: "Dáta",
      preview: "Náhľad",
      definition: "Definícia",
      visualization: "Spôsob zobrazenia",
      calculation: "Spôsob výpočtu",
      key: "Možnosť",
      value: "Hodnota",
      next: "Ďalej",
      back: "Späť",
      legend: "Legenda",
      count: "Počet",
      no_issues: "Bez hlásení",
      error: "Chyba výpočtu KPI",
      no_data: "Žiadne dáta. Skontrolujte nastavenia",
      submenu: {
        compute_now: "Prepočítať hneď",
        download: "Stiahnuť",
      },
    },
    refresh_rates: {
      0: "Žiadna",
      60: "1 Hodina",
      360: "6 Hodín",
      1440: "24 Hodín",
    },
    visualizations: {
      map: "Mapa",
      pie_chart: "Koláčový graf",
      donut_chart: "Prstencový graf",
      column_chart: "Stĺpcový graf",
      bar_chart: "Riadkový graf",
      line_chart: "Čiarový graf",
      area_chart: "Plošný graf",
      time_line: "Časová os",
      stacked_column_chart: "Vrstvený stĺpcový graf",
      stacked_bar_chart: "Vrstvený riadkový graf",
      table: "Tabuľka",
    },
    calculations: {
      ratio: "Pomer",
      issue_count: "Počty hlásení",
      time_series: "Časové rady",
      state_change: "Zmeny stavu",
      state_sum: "Súčet zmien stavov",
      detail_sum: "Súčet podľa poľa v detaile",
      map: "Mapa",
      map_colored: "Mapa podľa priority",
      consumption: "Spotreba",
      transaction_item_sum: "Súčet transakčných položiek",
    },
    display_type: {
      percentage: "Percentá",
      value: "Hodnota",
      label: "Názov",
      none: "Bez zobrazenia",
    },
  },
  connector: {
    connector: "Konektor",
    connectors: "Konektory",
    acc: "konektor",
    alert: genericAlertM,
    label: {
      type: "Typ",
      status: "Status",
      on: "Spustený",
      connector_type: "Typ konektora",
      login: "Prihlasovacie meno",
      url: "URL",
      queries: "Definícia dopytov",
      mail_receiver: {
        host: "Host",
        imap_port: "IMAP port",
        smtp_port: "SMTP port",
        user_name: "Prihlasovacie meno",
      },
      mail_ms_graph: {
        tenant_id: "Tenant ID",
        client_id: "Client ID",
        mailbox: "Mailbox",
      },
      pohoda_warehouse: {
        synchronize_warehouses: "Synchronizovať sklady",
        ico: "ICO",
      },
      ldap: {
        port: "Port",
      },
      ftp: {
        path: "Cesta k zdrojovému priečinku",
      },
      without: "Bez konektora",
    },
    type: {
      mail_receiver: "IMAP",
      mail_ms_graph: "MS Graph email",
      pohoda_warehouse: "Pohoda",
      ldap_auth: "LDAP",
      ftp: "FTP sťahovanie",
      ftp_sender: "FTP nahrávanie",
      sftp: "SFTP sťahovanie",
      sftp_sender: "SFTP nahrávanie",
      ca_sdm: "CA SDM REST",
      dpd_sap: "DPD SAP REST",
      document_saver: "Ukladač dokumentov",
    },
  },
  connector_run: {
    connector_run: "Spustenie",
    connector_runs: "Spustenia",
    acc: "spustenie",
    alert: genericAlertN,
  },
  connector_result: {
    connector_result: "Výsledok konektora",
    connector_results: "Výsledky konektora",
    acc: "výsledok konektora",
    alert: genericAlertM,
  },
  mapper: {
    mapper: "Priradenie",
    mappers: "Priradenia",
    acc: "priradenie",
    alert: genericAlertN,
    label: {
      mapper_type: "Typ priradenia",
      definition: "Definícia",
    },
    mapper_types: {
      mail_receiver_mapper: "Hlásenia z emailu",
      pohoda_warehouse_mapper: "Pohoda - mapovanie skladov",
      csv_mapper: "CSV -> majetok/osoby/hlásenia",
      csv_generator: "Majetok/osoby/hlásenia -> CSV",
      json_mapper: "JSON -> majetok/osoby",
      viskb_xml_generator: "Majetok -> VISKB XML",
    },
  },
  external_email: {
    external_email: "Externý email",
    external_emails: "Externé emaily",
    acc: "externý email",
    alert: genericAlertM,
    label: {
      submenu: {},
    },
  },
  permission: {
    permission: "Oprávnenie",
    permissions: "Oprávnenia",
    acc: "oprávnenie",
    selection: "Typ objektu",
    selectTypeAndPerson: "Vyberte typ objektu a osobu",
    tooltip: {
      see: "Vidieť",
      create: "Vytvoriť",
      update: "Upraviť",
      destroy: "Vymazať",
      manage_permissions: "Spravovať oprávnenia",
      see_in_object: "Vidieť v rámci objektu",
      update_in_object: "Upraviť v rámci objektu",
      see_issues: "Vidieť hlásenia",
      update_issues: "Upraviť hlásenia",
    },
    menu: {
      unset: "Nenastavené",
      unchanged: "Nezmenené",
      allowObject: "Povolené",
      allowObjectAndChildren: "Povolené s dedením",
      denyObject: "Zakázané",
      denyObjectAndChildren: "Zakázané s dedením",
    },
    label: {
      type: "Typ",
      permitted_persons: "Oprávnené osoby",
      persons_text_1: "Osoba",
      persons_text_2: "a všetky deti v organizačnej štruktúre.",
      roles_text_1: "Všetky osoby priradené k role",
      assignee: "(Oprávnenie z priradenia)",
      detail: "Detail oprávnenia",
      multiple_setup: "Nastaviť pre vybrané",
      entity: "Objekt",
      owner: "Vlastník oprávnenia",
    },
  },
  role: {
    role: "Rola",
    roles: "Roly",
    acc: "rolu",
    alert: genericAlertF,
    label: {
      configuration: "Konfigurácia",
      submenu: {
        setup_persons: "Nastaviť osoby",
      },
    },
  },
  user: {
    user: "Používateľ",
    users: "Používatelia",
    acc: "používateľa",
    alert: genericAlertM,
    label: {
      name_p: "Meno",
      block_status: "Blokovaný",
      two_factor_auth: "Dvojfaktorová autentifikácia",
      status: "Stav",
      is_turned_on: "je zapnutá",
      turn_on: "Zapnúť",
      is_turned_off: "je vypnutá",
      turn_off: "Vypnúť",
      turn_2fa_off: "Vypnúť dvojfaktorovú autentifikáciu",
      confirm: "Potvrdiť",
      two_factor_auth_label:
        "Naskenujte QR kód aplikáciou pre dvojfaktorovú autentifikáciu. Zadajte vygenerovaný kód a potvrďte.",
      submenu: {
        resend_instructions: "Poslať inštrukcie na obnovenie hesla",
      },
      impersonate: "Vydávať sa za používateľa",
      phone: "Telefónne číslo",
    },
    tier_types: {
      admin: "Administrátor",
      support: "Podpora",
      full: "Plný prístup",
      limited: "Limitovaný prístup",
      external: "Externý prístup",
    },
  },
  asset_category: {
    asset_category: "Kategória majetku",
    asset_categories: "Kategórie majetku",
    acc: "kategóriu",
    alert: genericAlertF,
  },
  person_category: {
    person_category: "Kategória osôb",
    person_categories: "Kategórie osôb",
    acc: "kategóriu",
    alert: genericAlertF,
  },
  category: {
    category: "Kategória",
    categories: "Kategórie",
    acc: "kategóriu",
    alert: genericAlertF,
    label: {
      remove_from: "Odstrániť z ",
      icon: "Ikona",
      floor_plan: "Pôdorys",
      persons: "Osoby",
      organization: "Organizácia",
      basic: "Základné",
      buildings: "Budovy",
      security: "Bezpečnosť",
      cars: "Autá",
      ikt: "Informačné a komunikačné technológie",
      cyber_sec: "Kybernetická bezpečnosť",
      energetics: "Energetika",
      submenu: {
        new_cat: "Vytvoriť novú kategóriu",
        existing_cat: "Pridať existujúcu kategóriu",
      },
    },
  },
  template: {
    template: "Šablóna",
    templates: "Šablóny",
    acc: "šablónu",
    alert: genericAlertF,
    detail: "Detail",
    empty: "K objektu nie je možné evidovať detail",
    raw: "Nespracované dáta",
    name: "Názov šablóny",
    label: {
      definition: "Definícia",
      key: "Kľúč",
      type: "Typ",
      template_on_objects: "Priradenie šablóny k objektom",
      uniqueLastValue: "Najvyššia hodnota",
      yes: "Áno",
      no: "Nie",
      field: "Pole",
      data_type: "Dátový typ",
      new_field: "Nové pole",
      new_option: "Nová možnosť",
      form: "Formulár",
      required: "Povinné",
      unique: "Unikátne",
      personal: "Osobný údaj",
      notify: "Notifikovať",
      submenu: {},
      fields: "Polia",
      multiselect: "Viacnásobný výber",
      conditional: "Zapnúť podmienený výber",
      select_from: "Podmienené výberom z",
      option: "Možnosť",
      for_checklist: "Šablóna kontrolného zoznamu",
      show_on_public_page: "Verejné hlásenie",
      show_on_public_map: "Verejná mapa",
    },
  },
  field: {
    field: "Pole",
    fields: "Polia",
    acc: "pole",
    alert: genericAlertN,
    data_types: {
      String: "Krátky text",
      Text: "Dlhý text",
      Integer: "Číslo",
      Date: "Dátum",
      Time: "Čas",
      Boolean: "Zašktávacie pole",
      Switch: "Prepínač",
      Select: "Výber z viacerých",
      Radio: "Označenie z viacerých",
      Picture: "Obrázok",
      Person: "Osoba",
      Asset: "Majetok",
      Issue: "Hlásenie",
      Password: "Heslo",
      DateTime: "Dátum a čas",
    },
  },
  field_assignment: {
    field_assignment: "Priradenie poľa",
    field_assignments: "Priradenia polí",
    acc: "priradenie poľa",
    alert: genericAlertN,
  },
  price_item: {
    price_item: "Položka",
    price_items: "Položky",
    acc: "položku",
    label: {
      price_type: "Typ položky",
      unit: "Jednotka",
      base: "Základná položka",
      batch: "Šarža",
      batches: "Šarže",
      quantity: "Množstvo",
      price: "Cena",
      gtin: "GTIN",
      code_1: "Kód 1",
      code_2: "Kód 2",
      submenu: {},
    },
    price_types: {
      material: "Materiál",
      work: "Práca",
      transport: "Doprava",
    },
    batch: {
      dialog: {
        header: "Zoznam šarží",
      },
      name: "Názov šarže",
      expiration: "Dátum exspirácie",
      quantity: "Množstvo",
      warehouse_quantity: "Na sklade",
      alert: "Na sklade sú položky so skoršou exspiráciou",
    },
    units: {
      // weight
      grams: "gramy",
      kilograms: "kilogramy",
      tons: "tony",
      ounces: "unce",
      pounds: "libry",
      // length
      millimeters: "milimetre",
      centimeters: "centimetre",
      meters: "metre",
      kilometers: "kilometre",
      feet: "stopy",
      miles: "míle",
      // area
      square_meters: "štvorcové metre",
      ares: "áre",
      hectares: "hektáre",
      square_feet: "štvorcové stopy",
      acres: "akre",
      // volume
      milliliters: "mililitre",
      liters: "litre",
      hectoliters: "hektolitre",
      cubic_meters: "kubické metre",
      pints: "pinty",
      gallons: "galóny",
      // time
      seconds: "sekundy",
      minutes: "minúty",
      hours: "hodiny",
      days: "dni",
      weeks: "týždne",
      months: "mesiace",
      years: "roky",
      // IT
      megabytes: "megabajty",
      gigabytes: "gigabajty",
      terabytes: "terabajty",
      // electric
      kilowatt_hours: "kilowatthodiny",
      milli_ampere_hours: "miliampérhodiny",
      // other
      pieces: "kusy",
      packages: "balenia",
      pallets: "palety",
      executions: "úkony",
      man_hours: "človeko-hodiny",
      engine_hours: "moto-hodiny",
      man_days: "človeko-dni",
    },
    units_short: {
      // weight
      grams: "g",
      kilograms: "kg",
      tons: "t",
      ounces: "oz",
      pounds: "lb",
      // length
      millimeters: "mm",
      centimeters: "cm",
      meters: "m",
      kilometers: "km",
      feet: "ft",
      miles: "mi",
      // area
      square_meters: "m²",
      ares: "ar",
      hectares: "ha",
      square_feet: "ft²",
      acres: "acre",
      // volume
      milliliters: "ml",
      liters: "l",
      hectoliters: "hl",
      cubic_meters: "m³",
      pints: "pt",
      gallons: "gal",
      // time
      seconds: "s",
      minutes: "min",
      hours: "hod",
      days: "d",
      weeks: "t",
      months: "mes",
      years: "r",
      // IT
      megabytes: "MB",
      gigabytes: "GB",
      terabytes: "TB",
      // electric
      kilowatt_hours: "kWh",
      milli_ampere_hours: "mAh",
      // other
      pieces: "ks",
      packages: "bal",
      pallets: "pal",
      executions: "úk",
      man_hours: "čl. hod",
      engine_hours: "moto hod",
      man_days: "čl. d",
    },
    alert: genericAlertF,
  },
  price_items_price_list: {
    price_items_price_list: "Cenníková položka",
    price_items_price_lists: "Cenníkové položky",
    acc: "cenníkovú položku",
    alert: genericAlertF,
  },
  warehouse: {
    warehouse: "Sklad",
    warehouses: "Sklady",
    acc: "sklad",
    alert: genericAlertM,
    label: {
      balance: "Zostatok",
      ordered: "Objednané",
      quantity: "Počet",
      warehouse_from: "Zo skladu",
      warehouse_to: "Na sklad",
      price_summary: "Celková cena",
      min: "Min.",
      opt: "Opt.",
      minimal: "Minimálne množstvo",
      optimal: "Optimálne množstvo",
      under_limit: "Prekročené minimálne množstvo",
      warehouse_transactions: "Transakcie",
      transaction_type: "Typ",
      changed_at: "Zmenené",
      transfer_to: "Presunúť na iný sklad",
      state: "Stav",
      submenu: {
        make_transaction: "Pridať materiál",
      },
      transactions: {
        in: "Príjem",
        out: "Výdaj",
        transfer: "Presun",
        transfer_from: "Presun zo skladu",
        transfer_to: "Presun na sklad",
        warehouse_order: "Objednávka na sklad",
      },
      states: {
        pending: "Rozpracovaná",
        closed: "Uzavretá",
        synchronized: "Synchronizovaná",
      },
    },
    dialog: {
      limit_header: "Nastavenie limitov",
      start: "Spustiť skenovanie položiek",
      scanner_header: "Skener položiek",
      add_manually: "Pridať položku manuálne",
      manual_header: "Pridanie položky",
    },
    messages: {
      transactionError: "Zadajte aspoň jednu zmenu",
    },
  },
  price_list: {
    price_list: "Cenník",
    price_lists: "Cenníky",
    acc: "cenník",
    alert: genericAlertM,
    label: {
      price: "Cena",
      prices: "Ceny",
      unit_price: "Jednotková cena",
      currency: "Mena",
      submenu: {
        setup_prices: "Nastaviť ceny",
      },
    },
  },
  work_report: {
    work_report: "Výkaz práce",
    work_reports: "Výkazy práce",
    acc: "výkaz práce",
    alert: genericAlertM,
    label: {
      price_list: "Použitý cenník: ",
      resources: "Množstvo",
      object: "Sklad / Osoba / Majetok",
      author: "Vytvoril",
      summary: "Celková spotreba s nákladmi",
      items: "Jednotlivé položky výkazu",
      total_price: "Celková cena",
      submenu: {},
      type: "Typ výkazu",
    },
  },
  checklist: {
    checklist: "Kontrolný zoznam",
    checklists: "Kontrolné zoznamy",
    acc: "kontrolný zoznam",
    alert: genericAlertM,
  },
  template_assignment: {
    template_assignment: "Priradenie šablóny k objektu",
    template_assignments: "Priradenia šablón k objektom",
    acc: "priradenie šablóny k objektu",
    alert: genericAlertN,
    label: {
      edit_header: "Priradenie šablóny",
    },
    usage: "Použitie",
  },
  dashboard_screen: {
    dashboard_screen: "Dashboard",
    dashboard_screens: "Dashboardy",
    acc: "dashboard",
    copy_dashboard: 'Kopírovať dashboard "{name}"?',
    setup: "Nastavenie dashboardov",
    public_dsh: "Verejné dashboardy",
    new_dashboard: "Nový dashboard",
    visualization: "Vizualizácia",
    refresh_rates: {
      0: "Žiadna",
      1: "1 Minúta",
      5: "5 Minút",
      15: "15 Minút",
    },
    alert: genericAlertM,
    label: {
      public: "Zdieľaný",
      refresh_rate: "Perióda obnovenia",
      no_public_dashboard_alert:
        "Nie sú dostupné žiadne zdieľané dashboardy na skopírovanie.",
      public_dashboard_alert:
        "Môžete si pozrieť a skopírovať nasledovné zdieľané dashboardy. Skopírovaný dashboard si možete " +
        "následne ľubovoľne upraviť aby spĺňal Vaše potreby.",
      submenu: {},
    },
  },
  dashlet: {
    dashlet: "Dlaždica",
    dashlets: "Dlaždice",
    acc: "dlaždicu",
    alert: genericAlertF,
    label: {
      order: "Poradie",
      size: "Šírka",
      setup_error: "Dlaždica nie je nastavená",
    },
    sizes: {
      3: "25 %",
      4: "33 %",
      6: "50 %",
      8: "66 %",
      9: "75 %",
      12: "100 %",
    },
  },
  subscription: {
    subscription: "Odber",
    subscriptions: "Odbery",
    acc: "odber",
    alert: genericAlertM,
    label: {
      subscription_type: "Typ odberu",
      subscribed_items: "Odoberané položky",
      type: "Typ",
      last_one: "Je potrebné vybrať aspoň jednu voľbu",
      issue_priority: "Pri akej priorite?",
    },
    subscription_types: {
      Service: "Služby",
      Contract: "Zmluvy",
      Rule: "Pravidlá",
      Asset: "Majetok",
      AssetCategory: "Kategórie majetku",
      Person: "Osoby",
      PersonCategory: "Kategórie osôb",
      Warehouse: "Sklady",
      PriceItem: "Cenníkové položky",
      Connector: "Konektory",
      Other: "Iné",
    },
  },
  entity_subscription: {
    entity_subscription: "Odoberaný objekt",
    acc: "odberaný objekt",
    alert: genericAlertM,
  },
  notification: {
    notification: "Notifikácia",
    notifications: "Notifikácie",
    acc: "notifikáciu",
    alert: genericAlertF,
    label: {
      empty: "Nemáte žiadne neprečítané notifikácie",
      from: "Od",
      mark_as_read: "Označit ako prečítanú",
      open_object: "Prejdite na tento objekt",
      notifications_list: "Zoznam notifikácií",
      on_object: "na objekt",
      read: "Prečítaná",
      read_all: "Označiť všetky ako prečítané",
      view_all: "Zobraziť všetky",
      created: "Vytvorená",
      notification_send_when: "Notifikácia bude odoslaná",
      when: "Kedy chcete byť notifikovaný?",
      how: "Ako chcete byť notifikovaný?",
      notification_time: "Čas oznámenia",
      notification_time_in_advance: "Čas oznámenia vopred",
      time: "Čas",
      before_expire: "Čas oznámenia pred vypršaním",
      before_batch_expire: "Čas oznámenia pred vypršaním šarže",
      before_notice_period: "Čas oznámenia pred začiatkom výpovednej lehoty",
    },
    types: {
      issue_create: "Pri vytvorení hlásenia",
      issue_close: "Pri uzavretí hlásenia",
      closed_issue_update: "Pri úprave uzavretého hlásenia",
      update: "Pri úprave objektu",
      fail: "Keď zlyhá konektor",
      mention: "Keď som spomenutý v poznámke na hlásení",
      note_create: "Keď je vytvorená nová poznámka na hlásení",
      assign: "Keď som priradený na hlásenie",
      issue_without_service_create: "Keď je vytvorené hlásenie bez služby",
      expire: "Keď vyprší platnosť",
      prolong: "Pri predĺžení",
      before_notice_period: "Pred začiatkom výpovednej lehoty",
      before_expire: "Predtým než vyprší platnosť",
      optimal_balance: "Keď sa prekročí optimálny počet",
      minimal_balance: "Keď sa prekročí minimálny počet",
      before_batch_expire: "Pred exspiráciou šarže",
      batch_expire: "Pri exspirácii šarže",
      response_time_expire: "Keď skončí čas zareagovania",
      before_response_time_expire: "Pred koncom času na zareagovanie",
      resolution_time_expire: "Keď skončí čas vyriešenia ",
      before_resolution_time_expire: "Pred koncom času na vyriešenie",
      issue_state_enter: "V konkrétnom stave ",
    },
    tooltip: {
      mail: "Notifikácia cez email",
      push: "Push notifikácia",
      sms: "SMS notifikácia",
    },
  },
  reminder: {
    reminder: "Pripomienka",
    reminders: "Pripomienky",
    acc: "pripomienku",
    alert: genericAlertF,
    label: {
      message: "Správa",
      notification_time: "Čas notifikácie",
    },
  },
  snippet: {
    snippet: "Preddefinovaný text",
    snippets: "Preddefinované texty",
    acc: "preddefinovaný text",
    alert: genericAlertM,
    label: {
      value: "Hodnota",
      public: "Zdieľaný",
    },
  },
  entity_document: {
    entity_document: "Priradenie dokumentu k objektu",
    entity_documents: "Priradenia dokumentov k objektom",
    acc: "priradenie dokumentu k objektu",
    dialog: {
      from_document: "Vytvoriť priradenie na objekt",
      from_entity: "Vytvoriť priradenie na dokument",
      hint: "Pre vyhľadávanie začnite písať",
    },
    alert: genericAlertN,
  },
  indoor_location: {
    indoor_location: "Vnútorná poloha",
    indoor_locations: "Vnútorné polohy",
    acc: "vnútornú polohu",
    alert: genericAlertF,
  },
  location: {
    location: "Poloha",
    locations: "Polohy",
    acc: "polohu",
    alert: genericAlertF,
    label: {
      indoor: "Interiér",
      outdoor: "Mapa",
      latitude: "Zemepisná šírka",
      longitude: "Zemepisná dĺžka",
      loadName: "Načítať názov",
      has_floor_plan: "Zapnúť používanie pôdorysu",
      layers: "Vrstvy",
      cadastre: "Katastrálna",
      basic: "Základná",
      public_issue_map: "Mapa hlásení",
      current: "Načítať aktuálnu polohu",
    },
    tooltip: {
      getCoordinates: "Získať súradnice podľa názvu miesta",
      disabled: {
        getCoordinates: "Zadajte celý názov miesta",
        current: "Prístup k polohe bol odmietnutý",
      },
    },
    hint: {
      latitude: "Zadajte v desatinnom formáte",
      longitude: "Zadajte v desatinnom formáte",
    },
    select_location: "Pre zmenu polohy kliknite na mapu",
    layer: "Pôdorys",
  },
  issue_note: {
    issue_note: "Poznámka",
    issue_notes: "Poznámky",
    acc: "poznámku",
    alert: genericAlertF,
    note_types: {
      private: "Súkromná",
      internal: "Interná",
      public: "Verejná",
    },
    note_types_acc: {
      private: "súkromnú",
      internal: "internú",
      public: "verejnú",
    },
    label: {
      from: "Od: ",
      displaying: "Zobrazené",
      send_email: "Poslať poznámku emailom",
      email_to: "Adresát",
      email_cc: "Kópia",
      email_bcc: "Skrytá kópia",
      loadMore: "Načítať viac",
      note_type: "Typ poznámky",
      role_private: "Súkromná poznámka pre hlásenie",
      linkCopied: "Odkaz na poznámku bol skopírovaný",
      new: "Nová poznámka",
      forward: "Preposlať {type} poznámku",
      reply: "Odpovedať na {type} poznámku",
      reply_to_all_emails: "Odpovedať na všetky emaily",
    },
    previous_note: "Predchádzajúca poznámka",
    hint: {
      note: "Vyvolanie preddefinovaného textu pomocou znaku *. Vyvolanie označenia objektov pomocou znaku @ pre osoby, $ pre majetok, % pre dokumenty a # pre hlásenia.",
    },
  },
  issue_assignee: {
    issue_assignee: "Priradená osoba",
    issue_assignees: "Priradené osoby",
    issue_resource: "Zdroj",
    issue_resources: "Zdroje",
    acc: "priradenú osobu",
    alert: genericAlertF,
    issue_resource_edit: "Plánovanie zdrojov",
    label: {
      range: "Rozsah",
      time: "Konkrétny čas",
      plan_start: "Plánovaný čas od",
      plan_end: "Plánovaný čas do",
      resource: "Zdroj",
      relation: "Vzťah",
    },
  },
  issue_link: {
    issue_link: "Prepojené hlásenie",
    issue_links: "Prepojené hlásenia",
    acc: "prepojené hlásenie",
    alert: genericAlertN,
    label: {
      block: "Blokovať",
      blocks: "Blokované hlásenia",
      is_blocked_by: "Zablokované hláseniami",
      related: "Súvisiace hlásenia",
    },
  },
  assets_issue: {
    assets_issue: "Majetok na hlásení",
    assets_issues: "Majetok na hláseniach",
    acc: "priradenie majetku k hláseniu",
    issue: "Majetok priradený k hláseniu",
    asset: "Hlásenie priradené k majetku",
    alert: genericAlertM,
    label: {
      solve_status: "Stav riešenia",
      not_run: "Neriešené",
      success: "Úspešná kontrola",
      fail: "Neúspešná kontrola",
      warning: "Varovanie",
    },
  },
  person_role: {
    person_role: "Priradenie osoby k role",
    person_roles: "Priradenia osôb k rolám",
    acc: "priradenie osoby k role",
    alert: genericAlertN,
    label: {
      person_setup: "Nastaviť osoby pre rolu",
    },
  },
  person_asset: {
    person_asset: "Priradenie osoby k majetku",
    person_assets: "Priradenia osôb k majetku",
    acc: "priradenie osoby k majetku",
    alert: genericAlertN,
  },
  external_guid: {
    external_guid: "Identifikátor",
    external_guids: "Identifikátory",
    acc: "identifikátor",
    alert: genericAlertM,
    bar: "BAR kód",
  },
  jwt_session: {
    jwt_session: "Prihlásené zariadenie",
    jwt_sessions: "Prihlásené zariadenia",
    acc: "prihlásené zariadenie",
    alert: genericAlertN,
    label: {
      actual: "Aktuálne prihlásenie",
      browser: "Prehliadač",
      firebase_token: "Firebase token",
      last_ip: "Posledná IP adresa",
      location: "Poloha",
    },
  },
  tag_definition: {
    tag_definition: "Tag",
    tag_definitions: "Tagy",
    acc: "tag",
    alert: genericAlertM,
    label: {
      color: "Farba",
      without: "Bez tagu",
      add: "Pridať tag",
      new: "Vytvoriť nový tag",
      filter: "Filtrovať",
    },
  },
  issue_priority: {
    issue_priority: "Priorita hlásenia",
    issue_priorities: "Priority hlásení",
    acc: "prioritu hlásenia",
    alert: genericAlertF,
    label: {
      priority: "Priorita",
      order: "Poradie",
      without: "Bez priority",
    },
  },
  issue_state: {
    issue_state: "Stav hlásenia",
    issue_states: "Stavy hlásení",
    acc: "stav hlásenia",
    alert: genericAlertM,
    label: {
      hidden: "Skrytý",
      state: "Stav",
      close_issue_state: "Stav hlásenia pre uzavretie",
      plan_issue_state: "Stav hlásenia pre plánovanie",
      create: "Vytvoriť",
      start: "Spustiť",
      pause: "Zastaviť",
      continue: "Pokračovať",
      finish: "Ukončiť",
      approve: "Schváliť",
      without: "Bez stavu",
    },
  },
  issue_state_transition: {
    issue_state_transitions: "Prechody medzi stavmi hlásenia",
    issue_state_transition: "Prechod medzi stavmi hlásenia",
    acc: "prechod medzi stavmi hlásenia",
    alert: genericAlertM,
    label: {
      source_state: "Pôvodný stav",
      target_state: "Cieľový stav",
      name: "Názov hlásenia",
      description: "Popis hlásenia",
      solution: "Riešenie hlásenia",
      resolve_at: "Vyriešiť dňa",
      service: "Služba",
      priority: "Priorita hlásenia",
      note: "Poznámka",
      public_token_valid: "Verejné",
      checklists: "Vyriešené kontrolné zoznamy",
      blocking_issues: "Priradené blokujúce hlásenia",
      work_reports: "Zadaný výkaz práce",
      assets: "Majetok",
      files: "Súbory",
      persons: "Osoby",
      custom_fields: "Vlastné polia",
      tag_assignments: "Tagy",
      requirements: "Požiadavky prechodu",
      currently: "Aktuálne",
      display: "Zobraziť",
      validate: "Validovať",
      operation: "Operácia",
      close_transaction: "Uzavrieť transakciu",
    },
    value: {
      optional: "Voliteľné pole",
      required: "Povinné pole",
      not_validated: "Nevalidované",
      all_done: "Všetky splnené",
      one: "Aspoň jeden zadaný",
      order: "Objednávka",
      warehouse_order: "Objednávka na sklad",
      in: "Príjem na sklad",
      out: "Výdaj zo skladu",
      transfer: "Presun medzi skladmi",
      checklists: "Kontrolný zoznam",
      all_closed: "Všetky uzavreté",
      true: "Áno",
      false: "Nie",
    },
  },
  person_relation: {
    person_relation: "Vzťah osoby",
    person_relations: "Vzťahy osôb",
    acc: "vzťah osoby",
    alert: genericAlertM,
  },
  role_issue_state_transition: {
    role_issue_state_transition: "Priradenie prechodu medzi stavmi k role",
    role_issue_state_transitions: "Priradenia prechodov medzi stavmi k rolám",
    acc: "priradenie prechodu medzi stavmi k role",
    alert: genericAlertN,
  },
  profile: {
    profile: "Profil",
    profiles: "Profily",
    acc: "profil",
    settings: "Nastavenia",
    alert: genericAlertM,
    email: "Email",
    change_email: "Zmeniť",
    password: "Heslo",
    change_password: "Zmeniť",
    password_current: "Prosím, zadajte Vaše súčasné heslo",
    password_new: "Nové heslo",
    password_new_c: "Potvrďte nové heslo",
    account: "Účet",
    preferences: "Preferencie",
    prefer_exact_dates: "Preferovať exaktné dátumy",
    dark_mode: "Tmavý mód",
    light_mode: "Svetlý mód",
    locale: "Jazyk",
    date_format: "Formát dátumu",
    time_format: "Formát času",
    time_zone: "Časová zóna",
    week_first_day: "Prvý deň v týždni",
    pagination: "Stránkovanie",
    home_page: "Domovská stránka",
    two_factor_auth: "Dvojfaktorová autentifikácia",
    devices: "Zariadenia",
    about: "O aplikácii",
    product: {
      more_1:
        "Viac o platforme AUSEMIO a jej licencovaní nájdete na {web}, kde nájdete aj obchodné kontakty.",
      more_2: "V prípade technických otázok nás kontaktujte na {mail}.",
      legal: {
        cookies: "Súbory Cookies",
        gdpr: "Zbieranie Dát",
      },
      install: {
        name: "Názov",
        id: "Identifikátor",
        version: "Verzia",
        administration: "Správca",
      },
    },
  },
  license: {
    license: "Licencia",
    label: {
      type: "Typ používateľa",

      monthly: "Mesačná",
      yearly: "Ročná",
    },
    items: {
      title: "Licencia",
      payment: "Úhrada",
      users: "Používatelia",
      storage: "Úložisko",
      of: "z",
      modules: "Moduly",
      connectors: "Konektory",
    },
  },
  administration: {
    administration: "Administrácia",
    administrations: "Administrácie",
    acc: "administráciu",
    alert: genericAlertF,
  },
  import: {
    import: "Import",
    imports: "Import",
    acc: "import",
    alert: genericAlertM,
    label: {
      link_to: "Nalinkovať dokument na",
      export_question: "Chcete exportovať {model}?",
      export_text: "Vyfiltrované {model} budú exportované v .xlsx formáte",
      custom_export_text: "{model} budú exportované v .xlsx formáte",
      failed_row_number: "Číslo chybného riadka",
      failed_message: "Chybová správa",
      operation: "Operácia",
      status: "Status",
      stop: "Zastaviť import",
    },
  },
  implementation: {
    implementation: "Implementácia",
    implementations: "Implementácie",
    acc: "implementáciu",
    alert: genericAlertF,
    smtp: {
      label: {
        address: "Adresa",
        authentication: "Autentifikácia",
        domain: "Doména",
        host: "Host",
        openssl: "Nastavenie overenia OpenSSL",
        port: "Port",
        starttls: "Povolené STARTTLS",
        tls: "TLS",
        user_name: "Používateľské meno",
      },
    },
    menu: {
      notice: "Oznam",
      calendar: "Kalendár",
      statistics: "Štatistika",
      health_check: "Kontrola konzistencie",
      mail_server: "Emailový server",
      issue_state_transitions: "Prechody medzi stavmi hlásenia",
      user_settings: "Predvolené nastavenia používateľov",
      document_settings: "Nastavenia dokumentov",
      new_passwd: "Nastaviť nové heslo",
      public_form: "Verejný formulár pre nahlasovanie",
      background_jobs: "Práce na pozadí",
      installation: "Inštalácia",
      holidays: "Sviatky",
      branding: "Branding",
    },
    label: {
      // issue states and priorities dialog
      color: "Farba",
      db_representation: "Reprezentácia v databáze",
      max_filesize: "Maximálna veľkosť v MB",
      compress_dimension: "Maximálny rozmer obrázka pri komprimovaní v px",
      notice: "Oznam",
      setup_notice: "Nastaviť oznam",
      severity: "Závažnosť",
      category: "Vyberte kategóriu pre vygenerovanie import skriptu",
      generate: "Generovať XLSX súbor",
      read_file: "Načítať súbor pre import",
      run_import: "Spustiť import",
      new_import: "Nový import",
      hide_table:
        "Skryť tabuľku (odporúčané pri spracovaní 100 a viac riadkov)",
      public_form: {
        form: "Celý formulár pre nahlasovanie",
        step_1: "Krok 1 (súbory)",
        step_2: "Krok 2 (skenovanie QR)",
        step_3: "Krok 3 (poloha)",
      },
      health_check: {
        asset_no_category: "Majetok bez kategórie",
        person_no_category: "Osoby bez kategórie",
        asset_no_parent: "Majetok neumiestnený v strome",
        person_no_parent: "Osoby neumiestnené v strome",
        document_no_parent: "Dokumenty neumiestnené v strome",
        documents_over_limit: "Dokumenty presahujúce limit",
      },
      severity_levels: {
        info: "Informácia",
        error: "Chyba",
        success: "Úspech",
        warning: "Varovanie",
      },
      documents: {
        person_documents: "Dokumenty osôb",
        person_avatars: "Avatary osôb",
        person_detail: "Detail osôb",
        person_reports: "Reporty osôb",
        asset_documents: "Dokumenty majetkov",
        asset_avatars: "Avatary majetkov",
        asset_detail: "Detail majetkov",
        asset_reports: "Reporty majetkov",
        asset_checklists: "Kontrolné zoznamy majetkov",
        contract_documents: "Dokumenty zmlúv",
        service_documents: "Dokumenty služieb",
        rule_documents: "Dokumenty pravidiel",
        issue_documents: "Dokumenty hlásení",
        issue_detail: "Detail hlásení",
        issue_reports: "Reporty hlásení",
        audit_documents: "Dokumenty auditov",
        audit_evidences: "Evidencia auditov",
        audit_reports: "Reporty auditov",
        price_list_reports: "Reporty cenníkov",
        default_folder_settings: "Predvolené nastavenia ukladania súborov pre:",
      },
    },
    hint: {
      documents_import: {
        filters: "Dokumenty budú priradené k vyfiltrovaným objektom",
      },
    },
  },
  activity: {
    activity: "Aktivita",
    activities: "Aktivity",
    acc: "aktivitu",
    label: {
      activity_type: "Typ aktivity",
      author_type: "Typ autora",
      changed: "Zmenené",
      changed_at: "Zmenené",
      changed_by: "Zmenil",
      from: "Pred",
      to: "Po",
      diff: "Rozdiely",
      attribute: "Atribút",
    },
    list_label: {
      created_at: "Vytvorené",
      created_by: "Vytvoril",
      activity_type: "Typ aktivity",
      object_type: "Typ objektu",
      object_id: "ID objektu",
      name: "Názov",
      before: "Pred",
      after: "Po",
    },
    activity_types: {
      create: "Vytvorenie",
      update: "Úprava",
      destroy: "Zmazanie",
      log_in: "Prihlásenie",
      log_out: "Odhlásenie",
      other: "Iný",
      archive: "Archivovanie",
      unarchive: "Obnovenie",
    },
    timeline: {
      chips: {
        issue: "@:issue.issue",
        tag_assignment: "@:tag_definition.tag_definitions",
        entity_document: "@:document.documents",
        issue_note: "@:issue_note.issue_notes",
        issue_assignee_issue: "@:issue_assignee.issue_resources",
        assets_issue_issue: "@:asset.assets",
        work_report: "@:work_report.work_reports",
        location: "@:location.location",
        issue_link: "@:issue_link.issue_links",
        asset: "@:asset.asset",
        issue_assignee_asset:
          "@:issue.issues (@:issue_assignee.issue_resource)",
        person_asset: "@:person.persons",
        assets_issue_asset: "@:issue.issues",
        external_guid: "@:external_guid.external_guid",
      },
      issue: {
        create: "vytvoril/a toto",
        update: "upravil/a toto",
        destroy: "vymazal/a toto",
        state_change: "zmenil/a stav na",
      },
      asset: {
        create: "vytvoril/a tento",
        update: "upravil/a tento",
        destroy: "vymazal/a tento",
        archive: "archivoval/a tento",
        unarchive: "obnovil/a tento",
      },
      assets_issue: {
        create: "priradil/a majetok k hláseniu",
        update: "upravil/a majetok na hlásení",
        destroy: "odobral/a majetok z hlásenia",
      },
      issue_link: {
        create: "priradil/a",
        update: "upravil/a",
        destroy: "vymazal/a",
      },
      reminder: {
        create: "nastavil/a",
        update: "upravil/a",
        destroy: "odobral/a",
      },
      issue_assignee: {
        create: "priradil/a",
        update: "upravil/a",
        destroy: "odobral/a",
      },
      entity_document: {
        create: "priradil/a",
        update: "upravil/a",
        destroy: "odobral/a",
      },
      person_asset: {
        create: "priradil/a",
        update: "upravil/a",
        destroy: "odobral/a",
      },
      work_report: {
        create: "priradil/a",
        update: "upravil/a",
        destroy: "odobral/a",
      },
      external_guid: {
        create: "priradil/a",
        update: "upravil/a",
        destroy: "odobral/a",
      },
      location: {
        create: "pridal/a",
        update: "upravil/a",
        destroy: "odobral/a",
      },
      issue_note: {
        create: "pridal/a",
        update: "upravil/a",
        destroy: "odobral/a",
      },
      tag_assignment: {
        create: "priradil/a",
        update: "upravil/a",
        destroy: "odobral/a",
      },
      generic: {
        issue: "hlásenie",
        asset: "majetok",
        issue_link: "link na hlásenie",
        issue_assignee: "zdroj",
        assets_issue: "",
        reminder: "pripomienku",
        issue_note: "poznámku",
        work_report: "výkaz práce",
        entity_document: "dokument",
        person_asset: "osobu",
        external_guid: "identifikátor",
        location: "polohu",
        tag_assignment: "tag",
      },
    },
  },
  filter: {
    filter: "Filter",
    filters: "Filtre",
    acc: "filter",
    alert: genericAlertM,
    existence_levels: {
      all: "Všetky",
      archived: "Iba archivované",
    },
    otp_required_for_login_levels: {
      two_factor_auth: "2FA",
      without_two_factor_auth: "Bez 2FA",
    },
    blocked_levels: {
      blocked: "Blokovaní",
      not_blocked: "Neblokovaní",
    },
    public_levels: {
      shared: "Zdieľané",
      private: "Súkromné",
    },
    on_levels: {
      on: "Zapnuté",
      off: "Vypnuté",
    },
    public_token_valid_levels: {
      public: "Verejné",
      internal: "Interné",
    },
    not_planned_levels: {
      planned: "Naplánované",
      not_planned: "Nenaplánované",
    },
    below_min_levels: {
      below: "Pod minimom",
      above: "Nad minimom",
    },
    above_opt_levels: {
      below: "Pod optimom",
      above: "Nad optimom",
    },
    with_user_levels: {
      with_user: "S používateľom",
      without_user: "Bez používateľa",
    },
    actions: {
      subheaders: {
        savedFilters: "Uložené filtre",
      },
    },
  },
  data_set: {
    data_set: "Filter",
    data_sets: "Filtre",
    acc: "filter",
    alert: genericAlertM,
    label: {
      target_entity: "Cieľový dátový typ",
      definition: "Definícia",
      edit_filter_definition: "Editovať definíciu filtra",
      edit_definition: "Editovať definíciu",
      definition_from_filter: "Načítať definíciu z filtra",
      public: "Zdieľaný",
    },
    target_entities: {
      Activity: "@:activity.activity",
      Asset: "@:asset.asset",
      AssetCategory: "@:asset_category.asset_category",
      AssetGroup: "@:asset_group.asset_group",
      AssetsIssue: "@:checklist.checklist",
      AutomatedAction: "@:automated_action.automated_action",
      Contract: "@:contract.contract",
      DataSet: "@:data_set.data_set",
      Document: "@:document.document",
      ExternalEmail: "@:external_email.external_email",
      Field: "@:field.field",
      Issue: "@:issue.issue",
      Kpi: "@:kpi.kpi",
      Person: "@:person.person",
      PersonCategory: "@:person_category.person_category",
      PriceItem: "@:price_item.price_item",
      PriceItemsWarehouse: "@:price_items_warehouse.price_items_warehouse",
      PriceList: "@:price_list.price_list",
      PrimaryAsset: "@:primary_asset.primary_asset",
      Report: "@:report.report",
      Requirement: "@:requirement.requirement",
      Role: "@:role.role",
      Service: "@:service.service",
      Snippet: "@:snippet.snippet",
      Subscription: "@:subscription.subscription",
      Threat: "@:threat.threat",
      Translation: "@:translation.translation",
      User: "@:user.user",
      Vulnerability: "@:vulnerability.vulnerability",
      Warehouse: "@:warehouse.warehouse",
      WarehouseTransactionItem:
        "@:warehouse_transaction_item.warehouse_transaction_item",
    },
    multi_operations: {
      add_note_to_issue: "Pridať poznámku",
      change_issue_state: "Zmeniť stav",
      link_to_document: "Priradiť dokument",
      change_parent: "Zmeniť umiestnenie v strome",
      link_assets_to_person: "Priradiť k osobe",
      link_persons_to_asset: "Priradiť majetok",
      link_documents_to_entity: "Priradiť k objektu",
      compress_documents: "Komprimovať obrázky",
      add_tag_to_entity: "Priradiť tag",
      remove_tag_from_entity: "Odobrať tag",
      add_person_to_issues: "Priradiť osobu",
      remove_person_from_issues: "Odobrať priradenú osobu",
      remove_assets_from_person: "Odobrať z osoby",
      archive_multiple: "Archivovať",
      destroy_multiple: "Zmazať",
      export: "Exportovať",
      report: "Generovať report",
    },
    multi_operations_hint: {
      generic:
        "Ostatné operácie nie je možné spustiť, pretože máte vyfiltrovaných viac ako 1000 položiek.",
    },
  },
  report: {
    report: "Report",
    reports: "Reporty",
    acc: "report",
    alert: genericAlertM,
    label: {
      target_entity: "Cieľový dátový typ",
      report_type: "Typ reportu",
      generate: "Generovať",
      submenu: {
        generate_report: "Generovať report",
        subheader: "Reporty",
      },
      contains_qr: "Obsahuje QR kód",
      contains_avatar: "Obsahuje avatara",
    },
    messages: {
      hint: "Report bude vygenerovaný na pozadí a súbor bude uložený do zvoleného priečinka. O dokončení budete informovaný notifikáciou v aplikácii.",
    },
    report_types: {
      single_entity: "Jeden cieľový objekt",
      data_set: "Filter",
    },
    target_entities: {
      Issue: "@:issue.issue",
      Asset: "@:asset.asset",
      Person: "@:person.person",
      PriceList: "@:price_list.price_list",
      Audit: "@:audit.audit",
      PrimaryAsset: "@:primary_asset.primary_asset",
      AssetGroup: "@:asset_group.asset_group",
    },
  },
  automated_action: {
    automated_action: "Automatická akcia",
    automated_actions: "Automatické akcie",
    acc: "automatickú akciu",
    alert: genericAlertF,
    label: {
      automated_action_type: "Typ automatickej akcie",
      run_type: "Typ spustenia",
      definition: "Definícia",
      edit_definition: "Editovať definíciu",
      on: "Zapnutá",
      activity: "Typ aktivity",
      author_type: "Typ autora",
      recipient_type: "Typ príjemcu",
      variable: "Premenná",
    },
    stepper: {
      basic: "Základné nastavenia",
      trigger: "Spúšťač",
      action: "Akcia",
    },
    automated_action_types: {
      notify: "Poslanie notifikácie",
      report: "Generovanie reportu",
      create: "Vytvorenie objektu",
      update: "Úprava objektu",
      archive: "Archivácia objektu",
      destroy: "Zmazanie objektov",
    },
    run_types: {
      now: "Okamžite",
      delayed: "Oneskorene",
    },
  },
  holiday: {
    holiday: "Sviatok",
    holidays: "Sviatky",
    acc: "sviatok",
    alert: genericAlertM,
    label: {
      country: "Krajina",
      date: "Dátum",
    },
  },
  sla: {
    sla: "SLA",
    slas: "SLA",
    acc: "SLA",
    alert: genericAlertF,
    label: {
      states: "Stavy",
      response_time: "Čas zareagovania",
      resolution_time: "Čas vyriešenia",
      response_final_states: "Odpoveď - koncové stavy",
      resolution_final_states: "Vyriešenie - koncové stavy",
      response_paused_states: "Odpoveď - pozastavené stavy",
      resolution_paused_states: "Vyriešenie - pozastavené stavy",
      setup_states: "Nastaviť stavy pre výpočet SLA",
      monday: "Pondelok",
      tuesday: "Utorok",
      wednesday: "Streda",
      thursday: "Štvrtok",
      friday: "Piatok",
      saturday: "Sobota",
      sunday: "Nedeľa",
      holiday: "Sviatok",
      holidays_countries: "Krajiny pre sviatky",
      from: "Od",
      to: "Do",
      limit_by_sla: "SLA Limit",
      actual_time: "SLA Skutočný čas",
      status: "SLA Status",
      response: "Odpoveď",
      resolution: "Vyriešenie",
    },
    statuses: {
      in_progress: "V procese",
      in_progress_overdue: "V procese, zmeškaná",
      resolved: "Splnená",
      resolved_overdue: "Splnená, zmeškaná",
      paused: "Pozdržaná",
      unknown: "Neznáma",
    },
  },
  risk_management: {
    risk_management: "Riadenie rizík",
    acc: "riadenie rizík",
    alert: genericAlertN,
  },
  vulnerability: {
    vulnerability: "Zraniteľnosť",
    vulnerabilities: "Zraniteľnosti",
    acc: "zraniteľnosť",
    alert: genericAlertF,
    label: {
      add: "Pridať zraniteľnosť",
    },
  },
  vulnerability_control: {
    vulnerability_control: "Priradenie opatrenia",
    vulnerability_controls: "Priradenie opatrení",
    acc: "priradenie opatrenia",
    alert: genericAlertF,
  },
  threat_vulnerability: {
    threat_vulnerability: "Priradenie zraniteľnosti",
    threat_vulnerabilities: "Priradenie zraniteľností",
    acc: "priradenie zraniteľnosti",
    alert: genericAlertN,
  },
  threat: {
    threat: "Hrozba",
    threats: "Hrozby",
    acc: "hrozbu",
    alert: genericAlertF,
    label: {
      add: "Pridať hrozbu",
      confidentiality: "Dopad na dôvernosť",
      integrity: "Dopad na integritu",
      availability: "Dopad na dostupnosť",
    },
  },
  category_threat: {
    category_threat: "Priradenie hrozby",
    category_threats: "Priradenie hrozieb",
    acc: "priradenie hrozby",
    alert: genericAlertN,
  },
  consequence: {
    consequence: "Dopad",
    consequences: "Dopady",
    acc: "dopad",
    alert: genericAlertM,
  },
  likelihood: {
    likelihood: "Pravdepodobnosť",
    likelihoods: "Pravdepodobnosti",
    acc: "pravdepodobnosť",
    alert: genericAlertF,
  },
  risk_level: {
    risk_level: "Úroveň rizika",
    risk_levels: "Úrovne rizík",
    acc: "úroveň rizika",
    alert: genericAlertF,
    label: {
      color: "Farba",
    },
  },
  risk: {
    risk: "Riziko",
    risks: "Riziká",
    acc: "riziko",
    alert: genericAlertN,
    label: {
      consequence: "Dopad",
      consequenceShort: "D",
      likelihood: "Pravdepodobnosť",
      likelihoodShort: "P",
      risk: "Riziko",
      riskShort: "R",
      valuation: "Hodnotenie rizika",
      not_rated: "Nehodnotené",
      network_graph: "Sieťový Graf",
      primary_asset: "Primárne aktívum",
      supporting_assets: "Kategória podporného aktíva",
      effectiveness: "Aplikované",
      planned_effectiveness: "Planovaná aplikácia",
      real_effectiveness: "Skutočná aplikácia",
      reduction: "Váha opatrenia",
      current_risk: "Aktuálne riziko",
      residual_risk: "Reziduálne riziko",
      treatment_kind: "Plán ošetrenia",
      risk_analysis: "Analýza rizík",
    },
    treatment_kind: {
      null: "-",
      reduce: "Zníženie",
      accept: "Akceptácia",
      share: "Zdieľanie",
    },
  },
  primary_asset: {
    primary_asset: "Primárne aktívum",
    primary_assets: "Primárne aktíva",
    acc: "primárne aktívum",
    alert: genericAlertN,
    confidentiality_types: {
      public: "Verejné",
      internal: "Interné",
      protected: "Chránené",
      strictly_protected: "Prísne chránené",
    },
    integrity_types: {
      low: "Nízka",
      medium: "Stredná",
      high: "Vysoká",
    },
    availability_types: {
      low: "Nízka",
      medium: "Stredná",
      high: "Vysoká",
    },
  },
  asset_group: {
    asset_group: "Podporné aktívum",
    asset_groups: "Podporné aktíva",
    acc: "podporné aktíva",
    alert: genericAlertF,
    label: {
      add: "Pridať podporné aktíva",
    },
  },
  soa: {
    soa: "Vyhlásenie o aplikovateľnosti",
    soas: "Vyhlásenia o aplikovateľnosti",
    acc: "vyhlásenie o aplikovateľnosti",
    alert: genericAlertN,
    label: {
      reason: "Dôvod",
      applicable: "Aplikovateľné",
    },
  },
  soa_control: {
    soa_control: "Opatrenie",
    soa_controls: "Opatrenia",
    acc: "opatrenie",
    alert: genericAlertN,
  },
  asset_link: {
    asset_link: "Odkaz na majetok",
    asset_links: "Odkazy na majetok",
    acc: "odkaz na majetok",
    alert: genericAlertM,
  },
  control: {
    control: "Opatrenie",
    controls: "Opatrenia",
    acc: "opatrenie",
    alert: genericAlertN,
    label: {
      add: "Pridať opatrenie",
      applied: "Aplikované opatrenia",
      planned: "Plánované opatrenia",
      control: "1 opatrenie | {n} opatrenia | {n} opatrení",
      note: "Poznámka",
      budget: "Predpokladané náklady",
      time: "Predpokladaná pracnosť",
      deadline: "Termín",
      accept: "Akceptujem",
      warning_message:
        "Pri uložení sa prepíše hodnota aplikovaného opatrenia a vymažú sa všetky ostatné hodnoty.",
    },
  },
  controls_management: {
    controls_management: "Riadenie opatrení",
  },
  audit: {
    audit: "Audit",
    audits: "Audity",
    acc: "audit",
    alert: genericAlertM,
    label: {
      compliance: "Súlad",
      partial_compliance: "Čiastočný súlad",
      non_compliance: "Nesúlad",
      not_applicable: "Neaplikovateľné",
      unrated: "Nehodnotené",
      auditor: "Audítor",
      audit_organization: "Auditovaná organizácia",
      representative: "Zástupca organizácie",
      submission_date: "Dátum odovzdania",
      requirements: "Požiadavky",
      evidence: "Dôkazy",
      questions: "Otázky",
      findings: "Odpovede",
      note: "Poznámky",
      copy_questions: "Skopírovať otázky",
    },
  },
  recommendation: {
    recommendation: "Odporúčané opatrenie",
    recommendations: "Odporúčané opatrenia",
    acc: "odporúčané opatrenie",
    alert: genericAlertN,
  },
  question: {
    question: "Otázka",
    questions: "Otázky",
    acc: "otázku",
    alert: genericAlertF,
    label: {
      question: "Otázka",
      finding: "Odpoveď",
      note: "Poznámka",
    },
  },
  provision_question: {
    provision_question: "Otázka",
    provision_questions: "Otázky",
    acc: "otázku",
    alert: genericAlertF,
  },
  standard_soa_provision: {
    standard_soa_provision: "Požiadavka",
    standard_soa_provisions: "Požiadavky",
    acc: "požiadavku",
    alert: genericAlertF,
  },
  suggestion: {
    suggestion: "Návrh na zlepšenie",
    suggestions: "Návrhy na zlepšenia",
    acc: "návrh na zlepšenie",
    alert: genericAlertM,
  },
  standard: {
    standard: "Norma",
    standards: "Normy",
    acc: "normu",
    alert: genericAlertF,
    laws_standards: "Normy",
    label: {
      label: "Označenie",
      text: "Text",
      valid_from: "Platná od",
      valid_to: "Platná do",
    },
  },
  standard_soa: {
    standard_soa: "Vyhlásenie o aplikovateľnosti",
    standard_soas: "Vyhlásenia o aplikovateľnosti",
    acc: "vyhlásenie o aplikovateľnosti",
    alert: genericAlertN,
    label: {
      reason: "Dôvod",
      applicable: "Aplikovateľné",
    },
  },
  section: {
    section: "Sekcia",
    sections: "Sekcie",
    acc: "sekciu",
    alert: genericAlertF,
  },
  provision: {
    provision: "Ustanovenie",
    provisions: "Ustanovenia",
    acc: "ustanovenie",
    alert: genericAlertN,
  },
  requirement: {
    requirement: "Požiadavka",
    requirements: "Požiadavky",
    acc: "požiadavku",
    alert: genericAlertF,
    label: {
      status: "Stav",
    },
  },
  evaluation: {
    evaluation: "Ohodnotenie",
  },
  issue_tab: {
    redirect: "Otvoriť v Hláseniach",
  },
  warehouse_transaction: {
    warehouse_transaction: "Transakcia",
    warehouse_transactions: "Transakcie",
    acc: "Transakciu",
    alert: genericAlertF,
    label: {
      transaction_type: "Typ transakcie",
    },
  },
  warehouse_transaction_item: {
    warehouse_transaction_item: "Položka transakcie",
    warehouse_transaction_items: "Položky transakcie",
  },
  warehouse_batch: {
    warehouse_batch: "Šarža",
  },
  tag_assignment: {
    tag_assignment: "Priradenie tagu",
  },
  price_items_warehouse: {
    price_items_warehouse: "Skladová položka",
    price_items_warehouses: "Skladové položky",
    acc: "skladovú položku",
    alert: genericAlertF,
  },
  price_list_item: {
    price_list_item: "Položka cenníka",
  },
  category_hierarchy: {
    category_hierarchy: "Prepojenie kategórií",
  },
  background_job: {
    background_job: "Práca na pozadí",
    background_jobs: "Práce na pozadí",
    acc: "prácu na pozadí",
    alert: genericAlertF,
    label: {
      last_error: "Posledná chyba",
    },
  },
  external_password: {
    external_password: "Externé heslo",
  },
  permissions_application: {
    permissions_application: "Aplikačné oprávnenie",
  },
  service_issue_state_sla: {
    service_issue_state_sla: "Stav SLA na službe",
  },
  translation: {
    translation: "Preklad",
    translations: "Preklady",
    acc: "preklad",
    alert: genericAlertM,
    label: {
      key: "Kľúč",
      en: "Preklad EN",
      sk: "Preklad SK",
      en_short: "EN",
      sk_short: "SK",
    },
  },
  applied_control: {
    applied_control: "Aplikované opatrenie",
    alert: genericAlertN,
  },
  cia: {
    cia: "CIA",
  },
  primary_asset_group: {
    primary_asset_group: "Podporné aktíva na primárnom aktíve",
    alert: genericAlertF,
    acc: "podporné aktíva na primárnom aktíve",
  },
  risk_control: {
    risk_control: "Opatrenie na riziku",
  },
};

export default sk;
