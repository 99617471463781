import { defineStore } from "pinia";

export const useSettingsStore = defineStore("settings", {
  state: () => ({
    public_form: { enabled: false },
    brand: "AUSEMIO",
    version: "2024",
    logo_url: null,
    host: "",
    logo: null,
    instance_id: "",
    instance_name: "",
    instance_admin: "",
    payment: "",
    doc_max_size: 10,
    doc_compress_dimension: 800,
    doc_default_folders: {},
    notice: { message: "", severity: "success" },
    smtp: {},
    sms_api_key: null,
    license_quota: 0,
    license_users: {},
    license_connectors: {},
    license_parts: [],
    user_preferences: {},
    aad: null,
  }),
  getters: {
    appIcon: (state) => `/images/branding/${state.brand}/logo.png`,
    appIconDark: (state) => `/images/branding/${state.brand}/logo_dark.png`,
    backgroundImg: (state) => `/images/branding/${state.brand}/background.jpg`,
    loadingImg: (state) => `/images/branding/${state.brand}/loading_light.png`,
    loadingImgDark: (state) =>
      `/images/branding/${state.brand}/loading_dark.png`,
  },
  actions: {},
});
