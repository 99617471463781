import i18n from "../translations/i18n";

class _DatabaseModel {
  static bottomNav = { mobile: ["card"], desktop: ["list"] };
  static cardComponent = "base-card-component";
  static editDialogComponent = null;
  static tabs = [];
  static show = false;
  static showSize = 6;
  static filter = false;
  static operations = false;
  static createNewObject = { object: {}, params: {} };

  static cardFields(object) {
    return {
      title: object.name,
    };
  }

  static persistence_options = {
    1: "filter.existence_levels.archived",
    2: "filter.existence_levels.all",
  };

  static translatedSelect(prop) {
    return this[prop].map((key) => ({
      value: key,
      text: i18n.t(`${this.model}.${prop}.${key}`),
    }));
  }

  static isModel(...models) {
    return models.includes(this.model);
  }

  static tableHeaders = [];
  static customSort = true;
  static customSorts() {
    return this.tableHeaders.filter((h) => h.sortable !== false);
  }

  static detailTab = {
    titleKey: "template.detail",
    component: "TemplatesDetailTab",
    input_data: (context) => ({ object: context.object }),
    disabled: (context) => context.dataShow_disableUpdate,
  };
}

export default _DatabaseModel;
export { i18n };
