import Category from "../classes/Category";
import { useAuthUserStore } from "../stores/authUser";

class AssetCategory extends Category {
  static model = "asset_category";

  static tableHeaders = [
    { textKey: "form.label.name", value: "name" },
    { text: "", value: "tags", sortable: false },
  ];

  static createNewObject = {
    ...Category.createNewObject,
    object: { icon: "asset" },
  };

  static basicInfo(object) {
    return [
      {
        labelKey: "category.label.floor_plan",
        value: object.has_floor_plan,
        type: "boolean",
      },
      ...Category.basicInfo(object),
    ];
  }

  static tabs = [
    ...Category.tabs,
    {
      titleKey: "threat.threats",
      permission: "categories_tab_threats",
      component: "ThreatsTab",
      input_data: (context) => context.object.category_threats,
      disabled: () => {
        const authUserStore = useAuthUserStore();
        return !authUserStore.permissions.configuration;
      },
    },
  ];
}

export default AssetCategory;
